@media only screen and (max-width: 1799px) {
  /* .sign-in-inner-main {
    padding: 25px 150px 25px;
  } */

  .sign-in-inputs .small-text {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .social-media-icon {
    margin-bottom: 30px;
  }

  .sign-in-logo img {
    width: 140px;
  }
  .likes-modal-main .modal-body {
    height: 550px;
    min-height: unset;
    max-height: unset;
  }
  .all-comments-main {
    height: 380px;

    overflow-y: auto;
  }
  .all-sungle-likes-wrapper.all-comments-main {
    height: 420px;
  }
}

@media only screen and (max-width: 1699px) {
  .register-signin-logo {
    margin-bottom: 60px;
  }

  .title-with-arrow {
    margin-bottom: 25px;
  }

  .sign-up-steps-main .sign-in-inner-main {
    min-height: 700px;
  }

  .sign-up-steps-btns {
    top: -150px;
  }
  .write-something-input {
    width: 430px;
  }
  .register-signin-wrapper,
  .sign-in-wrapper {
    top: 55%;
  }
  .register-signin-wrapper,
  .sign-in-wrapper.diff-height-sign-in-wrapper {
    top: 50%;
  }
  .sign-in-wrapper.diff-height-sign-in-wrapper .sign-in-inner-main {
    min-height: 630px;
  }
  .sign-up-main-wrapper .sign-in-inner-main {
    min-height: 700px;
  }
  .all-followings {
    max-height: calc(100vh - 310px);
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1599px) {
  /* .modal-body {
    max-height: 400px;
  } */
}

@media only screen and (max-width: 1499px) {
  .profileImageSelection {
    margin-bottom: 20px;
  }
  .sign-in-wrapper {
    width: 650px;
  }
  button.countr-code {
    height: 40px;
  }
  .three-dots-btn {
    height: 40px;
    width: 40px;
  }
  .sign-in-inputs .form-control,
  .sign-in-inputs .form-select {
    padding: 9px 20px;
  }
  .sign-in-inner-main {
    min-height: 650px;
  }
  button.countr-code::after {
    top: 11px;
    right: 5px;
  }
  .red-btn,
  .gray-btn {
    height: 40px;
  }
  .sign-up-steps-main .sign-in-inner-main {
    min-height: 600px;
  }
  .register-signin-logo img {
    width: 130px;
    height: auto;
  }
  .register-signin-logo {
    margin-bottom: 40px;
  }
  .white-btn {
    height: 40px;
  }
  .white-btn-diffborder {
    height: 40px;
  }
}

@media only screen and (max-width: 1399px) {
  .sign-in-main {
    position: relative;
    /* height: 120vh; */
  }

  /* .sign-in-main.sign-up-steps-main {
    height: 140vh;
  } */

  .sign-in-inner-main {
    min-height: 620px;
  }

  .sign-in-inner-all,
  .sign-up-steps-btns {
    width: 450px;
  }

  .sign-in-wrapper {
    width: 570px;
    top: 54%;
  }
  .only-sign-in-main .sign-in-inner-main {
    min-height: 520px;
  }
  .sign-in-logo img {
    width: 100px;
  }
  .sign-in-logo {
    margin-bottom: 20px;
  }
  .sign-in-inputs .small-text {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  /* .likes-modal-main .modal-body {
    min-height: 600px;
  } */
  .social-media-icon img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
  .social-media-icon {
    margin-bottom: 15px;
  }
  .image-select {
    width: 70px;
    height: 70px;
  }
  .sign-up-steps-main .sign-in-inner-main {
    min-height: 520px;
  }
  .settings-matches-scores-main .recent-result-wrapper {
    background-size: cover;
  }
  .settings-matches-scores-inner-single .my-single-booking {
    margin-bottom: 10px;
  }
  /* .modal-body {
    max-height: 340px;
  } */
}

@media only screen and (max-width: 1299px) {
  .big-container {
    max-width: 90%;
  }
  .small-container {
    max-width: 90%;
  }
  .home-logo-wrapper img {
    width: 180px;
  }

  .social-media-icon {
    margin-bottom: 30px;
  }

  .register-signin-wrapper {
    width: 450px;
  }
  .activity-section-bottom {
    max-height: 100%;
    overflow-y: hidden;
  }
  .register-signin-buttons .small-text::before,
  .register-signin-buttons .small-text::after {
    width: 145px;
  }

  /* .sign-in-inner-main {
    padding: 25px 100px 25px;
  } */

  .sign-in-inner-all {
    max-width: 450px;
    margin: 0 auto;
  }

  .sign-in-wrapper {
    width: 700px;
  }
  .find-clubs-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .club-detail-main-inner-wrapper {
    flex-direction: column;
  }
  .club-detail-main-inner-wrapper .col-xl-7,
  .club-detail-main-inner-wrapper .col-xl-5 {
    width: 100%;
  }
  .club-detail-right-fixed-width {
    max-width: 100%;
    margin-left: unset;
  }
  .width100inresponsive {
    width: 100%;
  }
  .book-court-section {
    margin-top: 20px;
  }
  .booking-detail-right-main-wrapper {
    margin-left: auto;
    max-width: 520px;
  }
  .post-section {
    max-width: 100%;
  }
  .all-suggestion-wrapper {
    width: 665px;
  }
  .page-top-section-middle-available-content-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .page-top-section-middle-available-content-single-content {
    width: 100%;
  }
  .page-top-section-middle-tab .nav-pills {
    position: relative;
    left: -50px;
  }
}

@media only screen and (max-width: 1199px) {
  :root {
    --font-26: 24px;
    --font-24: 22px;
    --font-22: 20px;
  }
  .register-signin-buttons .small-text::before,
  .register-signin-buttons .small-text::after {
    width: 135px;
  }
  .sign-in-wrapper {
    width: 600px;
  }
  .sign-up-steps-main .sign-in-inner-main {
    min-height: 480px;
  }
  .sign-in-wrapper {
    width: 570px;
    top: 50%;
  }
  .all-my-bookings {
    grid-template-columns: repeat(2, 1fr);
  }
  .booking-detail-right-main-wrapper {
    margin-left: unset;
    max-width: 100%;
    margin-top: 30px;
  }
  .all-suggestion-wrapper {
    width: 100%;
  }
  .create-btn,
  .write-something-main,
  .single-post-wrapper {
    padding: 10px;
  }
  .suggestion-single {
    min-width: 135px;
  }
  .likes-modal-main .modal-body {
    height: 450px;
  }
  .single-notification-single {
    grid-gap: 9px;
  }
  .all-likes-wrapper h5.likes-title {
    margin-bottom: 15px;
  }
  /* .all-comments-main {
    max-height: 390px;
    overflow-y: auto;
  } */
  .all-comments-main {
    height: 300px;
  }
  .all-sungle-likes-wrapper.all-comments-main {
    height: 330px;
  }
  .like-modal-inner-wrapper .write-comment-here {
    width: 46%;
  }

  img.comment-here-profile-image {
    width: 47px;
    height: 45px;
  }
  .all-likes-wrapper .write-comment-here .search-input-group {
    height: 60px !important;
  }
  .settings-right-side-main {
    padding: 15px;
    margin-left: 15px;
  }
  .settings-aside-main {
    padding: 15px;
  }
  .all-post-section-main .recent-result-scores-wrapper h6 {
    padding: 0 30px;
  }
  .post-section .slick-list img {
    height: 300px;
  }

  .AddtocartmodalMain .modal-body,
  .court-ratings-modal-main .modal-body,
  .create-post-modal-main .modal-body {
    min-height: 340px;
  }
  .page-top-section-middle-tab .nav-item a {
    padding: 10px 20px;
  }
  .page-top-section-middle-tab .nav-pills {
    left: -140px;
  }
  .CoachDeatail-main-wrapper .SingleCoachWithStatus-main {
    max-width: 100%;
    margin-bottom: 20px;
  }
  .CoachDetailModal-book-court-section-wrapper {
    max-height: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  :root {
    --font-20: 18px;
  }
  .club-detail-content-wrapper h3 {
    font-size: 25px;
  }
  .profile-detail-page-right-inner img.profile-detail-page-right-inner-image {
    width: 90px;
    height: 90px;
  }
  .all-likes-wrapper {
    padding-left: 10px;
  }
}
@media only screen and (max-width: 991px) {
  :root {
    --font-18: 16px;
  }
  .main-navigations {
    display: block;
    margin-top: 15px !important;
  }
  .navbar-collapse a {
    display: block;
    text-align: left;
    margin-bottom: 10px;
  }
  .like-modal-inner-left .like-and-comment {
    align-items: flex-start;
    flex-direction: column;
    grid-gap: 5px;
  }
  .likes-signle button {
    height: 30px !important;
    font-size: 11px !important;
    width: 60px !important;
  }
  /* .header-right-main {
    flex-direction: column;
    margin-top: 20px;
  }
  .header-icons-wrapper {
    margin-right: auto;
    margin-top: 10px;
  } */
  .find-clubs-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
  .page-top-section-right .search-input-group {
    max-width: 180px;
  }
  nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light:has(
      .navbar-collapse.show
    ) {
    padding-bottom: 20px;
  }
  .all-my-bookings {
    grid-template-columns: repeat(1, 1fr);
  }
  .page-top-section {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 15px;
  }
  .header-right-main {
    display: none !important;
  }
  .header-right-main-mobile {
    display: flex !important;
  }
  .header-right-main-mobile .search-input-group {
    margin-right: 5px;
    max-width: 190px;
  }
  .navbar-brand {
    margin: 0;
  }
  .navbar-toggler {
    padding: 5px;
  }
  .suggestion-single img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }
  .suggestion-single button.red-btn {
    height: 30px;
  }
  .write-something-profile {
    width: 40px;
    height: 40px;
  }
  .activity-section-bottom-single-bottom h6 {
    margin-bottom: 0;
  }
  .homeDashboard-right-main .activity-section-bottom-single-top {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 5px;
  }
  .header-main .dropdown-menu[data-bs-popper] {
    top: 150%;
    left: -90px;
  }
  .header-main .dropdown-menu.show a:not(:last-child) {
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .header-main .dropdown-menu.show {
    padding: 10px;
  }
  .modal-body {
    max-height: 450px;
  }
  .settings-wallet-header h5 {
    padding-bottom: 10px;
    margin-bottom: 0px;
  }
  .settings-wallet-header,
  .membership-element-single-wrap-up {
    padding: 10px;
  }
  .general-settings-main ul li:not(:last-child) {
    padding-bottom: 10px;
  }
  .general-settings-main ul li {
    margin-bottom: 10px;
  }
  .profile-detail-page-right {
    margin-left: 15px;
    padding: 10px;
  }
  span.profile-after-initials {
    left: 71%;
    bottom: 50%;
  }
  .post-following-followers-wrapper {
    margin-top: 20px;
  }
  .red-radio-btn .form-check:not(:last-child) {
    padding-right: 15px;
    margin-right: 15px;
  }
  .edit-profile-save {
    margin-top: 25px;
  }
  .settings-matches-scores-main .recent-result-scores-wrapper h6 {
    padding: 0 25px;
  }
  .court1Image img {
    width: 60px;
    height: 60px;
  }
  .post-section .slick-list img {
    height: 200px;
  }
  .all-post-section-main .recent-result-scores-wrapper h6 {
    padding: 0 20px;
  }
  .all-post-section-main .recent-result-wrapper {
    background-size: cover;
  }
  .upload-score-datepicker-wrapper .react-datepicker__input-container input,
  .upload-score-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
  }
  .single-notification-single-image img,
  img.comment-here-profile-image {
    width: 40px;
    height: 40px;
  }
  .all-likes-wrapper .write-comment-here .search-input-group {
    height: 55px !important;
  }
  .page-top-section-middle-tab .nav-pills {
    left: 55%;
    top: 4px;
    width: fit-content;
  }
  .page-top-section-middle-tab .nav-item a {
    padding: 7px 20px;
  }
  .page-top-section-middle-available-content-single-content-btns h5 {
    padding: 10px 10px;
  }
  .CoachDetailModal
    .page-top-section-middle-available-content-single-content-inner {
    flex-direction: column;
  }
  .CoachDetailModal-book-court-section-wrapper {
    padding-left: 10px;
  }
  .CoachDetailModal-book-court-section-wrapper table th,
  .CoachDetailModal-book-court-section-wrapper table td {
    padding: 15px !important;
  }
  .legends-of-available-slots-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 20px;
  }
}
@media only screen and (max-width: 800px) {
  .suggestion-single {
    min-width: 125px;
  }
  .like-modal-inner-wrapper .write-comment-here {
    width: 44%;
  }
}
@media only screen and (max-width: 767px) {
  .likes-modal-main .modal-body {
    overflow-y: auto;
  }
  .like-modal-inner-left {
    top: 0;
    padding: 0;
  }
  .all-likes-wrapper {
    padding-left: 0;
    border: none;
  }
}
@media only screen and (max-width: 575px) {
  .mobile-page-inner p {
    max-width: 380px;
  }
  .mobile-page-inner h4 {
    font-size: 26px;
    margin-top: 8px;
    max-width: 300px;
  }
  img.mobile-logo-image {
    width: 70px;
    height: 70px;
  }
  .mobile-page-wrapper-main {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  img.twomobImge {
    width: 75%;
  }
}
@media only screen and (max-width: 480px) {
  .mobile-page-inner span {
    padding-left: 70px;
    display: inline-block;
  }
  .mobile-page-inner span::after {
    width: 60px;
  }
  .mobile-page-inner p {
    max-width: 270px;
  }
}
@media only screen and (max-width: 389px) {
  :root {
    --font-16: 14px;
    --font-18: 15px;
  }
  .mobile-app-links img {
    width: 85%;
  }
  .mobile-page-inner h4 {
    font-size: 20px;
    max-width: 245px;
  }
  .mobile-app-links a {
    display: inline-block;
  }
  .mobile-page-inner p {
    max-width: 240px;
  }
}
