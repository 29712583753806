/* home page---------------------- */
.main-content {
  margin-top: 20px;
}
.home-main,
.register-signin-main,
.sign-in-main {
  position: relative;
  height: 100vh;
  background: url("../images/homebg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: auto;
}

.home-logo-wrapper,
.register-signin-wrapper,
.sign-in-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* overflow-y: auto; */
}
.font-red {
  color: var(--red) !important;
}
.font-green {
  color: #38a505 !important;
}
.font-yellow {
  color: #ffc42d !important;
}
.font-white {
  color: var(--white) !important;
}
.home-logo-wrapper img {
  width: 222px;
  height: 207px;
  object-fit: contain;
}

.white-btn {
  border-radius: 10px;
  background: transparent;
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #d9d9d9;
  height: 50px;
}

.white-btn.btn {
  color: var(--blackdiff);
}
.white-btn:hover,
.white-btn:focus {
  background: var(--white);
  color: var(--red);
  border: 1px solid #d9d9d9;
}

.white-btn-diffborder {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(41, 31, 31, 0.1);
  backdrop-filter: blur(9px);
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 50px;
}

.white-btn-diffborder:hover,
.white-btn-diffborder:focus {
  background: var(--white);
  color: var(--red);
  border: 1px solid #d9d9d9;
}

.red-btn {
  border-radius: 10px;
  background: var(--red);
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  border: 1px solid var(--red);
  line-height: normal;
  height: 50px;
}

.red-btn:hover,
.red-btn:focus,
.red-btn:active,
.red-btn:focus-visible,
.red-btn:focus-within {
  background: var(--white);
  color: var(--red);
  border: 1px solid var(--white);
}
.gray-btn {
  border-radius: 10px;
  background: #f6f7f9;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  border: 1px solid #f6f7f9;
  line-height: normal;
  height: 50px;
}
.gray-btn:hover {
  background: var(--blackdiff);
  color: var(--white);
  border: 1px solid var(--blackdiff);
}
.register-signin-wrapper {
  width: 500px;
}

.social-media-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  margin-bottom: 50px;
}

.register-signin-logo {
  margin-bottom: 90px;
}

.small-text {
  color: var(--text-color);
  text-align: justify;
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.register-signin-buttons .small-text {
  position: relative;
  padding: 15px;
  margin-bottom: 18px;
}

.register-signin-buttons .small-text::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 170px;
  top: 50%;
  left: 0;
  background: rgba(255, 255, 255, 0.25);
}

.register-signin-buttons .small-text::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 170px;
  top: 50%;
  right: 0;
  background: rgba(255, 255, 255, 0.25);
}

.register-signin-buttons button {
  margin-bottom: 20px;
}

button a {
  width: 100%;
}

/* Sign in page -------------------- */
.sign-in-inputs .form-control,
.sign-in-inputs .form-select {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  padding: 14px 20px;
  background-color: transparent !important;
  color: var(--white) !important;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.form-select {
  --bs-form-select-bg-img: url("../images/downArrow.svg") !important;
  background-size: 18px 18px !important;
}
.form-select.open-play-select {
  max-width: 208px;
}
.sign-in-inputs .form-control::placeholder {
  color: var(--white) !important;
}

.form-control:focus,
.form-select:focus {
  color: var(--white);
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.25) !important;
  outline: none;
  box-shadow: none !important;
}

.form-select option {
  color: var(--blackdiff);
}

.sign-in-wrapper {
  width: 800px;
}

.page-head {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-24);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 45px;
}

.sign-in-inner-main {
  /* padding: 58px 150px 30px; */
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: rgba(41, 31, 31, 0.1);
  backdrop-filter: blur(9px);
  min-height: 790px;
  position: relative;
}

.sign-in-inner-all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.sign-in-logo {
  margin-bottom: 40px;
}

.form-select.countr-code {
  max-width: 66px;
  background-size: 15px 12px !important;
  background-position-x: 41px;
}

.sign-in-inputs .small-text {
  margin-bottom: 15px;
  margin-top: 15px;
}

span.sign-up-link:hover {
  color: var(--red);
}

span.sign-up-link {
  cursor: pointer;
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dont-have-account {
  color: var(--text-color);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.forgot-password:hover {
  color: var(--red);
}

.close-icon {
  display: none;
}

.password-group button {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.25) !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
}

.password-group button:hover {
  background: transparent;
}

.password-group button:focus,
.password-group button:focus-visible,
.password-group button:active,
.password-group button:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  background: transparent !important;
}

.form-check {
  margin: 0;
  padding-left: 25px;
  display: flex !important;
  align-items: flex-start !important;
}

.form-check-label {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-select {
  width: 100px;
  height: 100px;
  object-fit: contain;
  background: var(--white);
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.sign-up-main .sign-in-inputs input,
.sign-up-main .sign-in-inputs .form-select,
.sign-up-main #showHidePassBtn {
  margin-bottom: 10px;
}

.profileImageSelection {
  margin-bottom: 15px;
}

.form-check-input {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: var(--white) !important;
  border-color: var(--white) !important;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("../images/checkboxTick.svg") !important;
  background-size: 11px 8px;
}

.sign-in-inputs .form-check {
  margin-top: 13px;
  margin-bottom: 30px;
}

.form-check-input:focus {
  border-color: var(--white) !important;
  outline: none !important;
  box-shadow: none !important;
}

.previous-step:hover {
  filter: brightness(0) saturate(100%) invert(24%) sepia(99%) saturate(4407%)
    hue-rotate(346deg) brightness(117%) contrast(87%);
}

.previous-step {
  cursor: pointer;
}

.invalid-feedback {
  margin-top: 0 !important;
  line-height: normal;
  margin-bottom: 0;
}

.medium-text {
  color: var(--white);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.forgot-password-main .medium-text {
  margin-top: 30px;
  margin-bottom: 20px;
}

.get-otp-btn {
  margin-top: 30px;
}

.input-group:has(.invalid-feedback) > * {
  border-color: #dc3545 !important;
}

.password-group:has(.invalid-feedback) > button {
  border-color: #dc3545 !important;
}

.title-with-arrow {
  margin-bottom: 45px;
}

.sign-up-steps-que {
  color: var(--white);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-24);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 45px;
}

.sign-up-steps-opt .white-btn-diffborder:not(:last-child) {
  margin-bottom: 12px;
}

.sign-up-steps-opt .white-btn-diffborder:hover {
  color: var(--blackdiff);
}

.sign-up-steps-btns {
  width: 500px;
  margin: 0 auto;
  margin-top: 40px;
  /* position: relative;
  top: -200px; */
}

/* .sign-up-steps-main .sign-in-inner-all {
  top: 40%;
} */
.header-main .dropdown-item {
  padding: unset;
}
.modal-header,
.offcanvas-header {
  background: #f6f7f9;
  padding: 0 !important;
  text-align: center;
  position: relative;
  border-bottom: none !important;
  justify-content: space-between;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.offcanvas-header {
  border-radius: 0 !important;
}
.modal-header .btn-close {
  position: absolute;
  right: 10px;
  margin: unset;
}
.btn-close {
  background: url("../images/btn-close.svg") no-repeat !important;
  opacity: 1;
  padding: 5px 8px !important;
}
.offcanvas-header .btn-close {
  padding: 5px 15px 5px 8px !important;
}
.modal-footer {
  border-top: none !important;
}

.modal-content {
  border-radius: 20px !important;
}

.modal-title,
.offcanvas-title {
  color: var(--blackdiff) !important;
  text-align: center;
  font-family: var(--Outfit) !important;
  font-size: var(--font-20) !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  padding: 12px 13px !important;
}

.otpDigitsMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 25px;
}

.otpDigits {
  width: 54px;
  height: 54px;
  border: 1px solid var(--text-light);
  border-radius: 10px;
  text-align: center;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalParagraph {
  color: var(--text-light);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-width: 358px;
  margin: 16px auto 30px;
}

.resendOtp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 12px;
}

.resendOtp p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.resendOtp p span {
  color: var(--red);
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  cursor: pointer;
  margin: 0;
}

.resendOtp p span.disable {
  opacity: 0.5;
  cursor: unset;
  pointer-events: none;
}

.resendOtp p:last-child {
  font-weight: 400;
}

.otp-btn.red-btn:hover {
  background: var(--blackdiff) !important;
  color: var(--white) !important;
}

.main-navigations {
  grid-gap: 30px;
}

.header-main .container-fluid {
  padding: 0 100px;
}

.header-main {
  background: var(--blackdiff);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 99;
}
.dropdown-item:hover,
.dropdown-item:focus {
  background: transparent;
}
.navbar-collapse a {
  color: var(--white) !important;
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 0;
}
.header-main .dropdown-menu.show {
  padding: 20px;
}
.navbar-collapse a.active,
.navbar-collapse a:hover {
  color: var(--red) !important;
}

.input-group-text {
  background: transparent;
  border: none;
  padding: 6px 5px 6px 10px;
}

.form-control {
  background: transparent;
}

.search-input-group .form-control {
  border: none;
}

.search-input-group {
  border-radius: 40px;
  border: 1px solid #4a4a4a;

  height: 44px;
}

.page-top-section-right .search-input-group {
  max-width: 248px;
}

.headerIcon {
  border-radius: 50%;
  border: 1px solid #4a4a4a;
  padding: 11px;
  cursor: pointer;
  position: relative;
}
.headerIcon h5 {
  position: absolute;
  font-size: 10px;
  font-family: var(--Outfit);
  font-weight: 500;
  top: 0px;
  right: -5px;
  background: var(--red);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  text-align: center;
  top: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
  padding: 15px 0;
} */

/* .dropdown-toggle::before {
  content: '';
  background: url(../images/player1.jpg) no-repeat;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  outline: 1px solid var(--white);
  outline-offset: 2px;
  border-radius: 50%;
} */
.header-right-main {
  grid-gap: 10px;
}

/* .header-dropdown {
  margin-left: 10px;
} */

.dropdown-menu.show a {
  color: var(--blackdiff) !important;
  text-align: left;
}
.dropdown-menu.show a:not(:last-child) {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}
.header-right-main .dropdown-toggle::after {
  content: "";
  background: url("../images/downArrow.svg") no-repeat !important;
  background-size: contain;
  width: 24px !important;
  height: 24px !important;
  border: none !important;
  vertical-align: middle !important;
}

.dropdown-menu.show a:hover {
  color: var(--red) !important;
}

.header-right-main .dropdown-menu[data-bs-popper] {
  right: 0 !important;
  left: auto !important;
  top: 42px !important;
}
.header-dropdown.dropdown button:focus,
.header-dropdown.dropdown button {
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
}
.header-profile-image {
  width: 46px;
  height: 46px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid var(--white);
  cursor: pointer;
}
.header-profile-image img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.write-something-input:focus {
  background: #f6f7f9;
}

.write-something-input {
  border-radius: 68px;
  background: #f6f7f9;
  width: 460px;
  padding: 15px;
  color: var(--blackdiff) !important;
}

.write-something-profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}

.write-something-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #66666650;
}

.write-something-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #eae9e9;
}

.write-something-top button.publish-btn {
  color: var(--red);
  text-align: right;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: none;
  border: none;
}

.create-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  grid-gap: 6px;
  background: #f4f4f4;
  padding: 16px;
  border: none;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.create-btn:hover {
  background: #feeff0;
}

.write-something-bottom {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.write-something-main {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 15px;
  position: sticky;
  top: 76px;
  z-index: 9;
}

.single-post-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.profile-and-time {
  grid-gap: 12px;
}
.post-section .slick-dots {
  display: none !important;
}
.username-with-time P {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.username-with-time h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.username-with-time h5 span {
  color: var(--text-light);
  cursor: auto;
}

.single-post-top img {
  cursor: pointer;
}

.single-post-wrapper {
  padding: 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
}

.post-section {
  max-width: 100%;
  /* height: 100%; */
  /* aspect-ratio: 10 / 8; */
}

.post-section img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  background: lightgray 50% / cover no-repeat;
}
.post-section .slick-list img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  background: lightgray 50% / cover no-repeat;
  height: 400px;
  cursor: pointer;
}
.single-post-bottom {
  margin-top: 12px;
}

.like-and-comment {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.like-with-like-number p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.like-with-like-number {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  cursor: pointer;
}

.post-detail p {
  color: #666;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
  word-wrap: break-word;
}

.post-detail {
  margin-top: 15px;
}

.suggestion-title a span {
  color: #666;
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.suggestion-title a {
  display: flex;
  align-items: center;
}

.suggestion-title a:hover span {
  color: var(--red);
}

.suggestion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.suggestion-single img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #66666650;
}

.suggestion-single {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #fff;
  text-align: center;
  min-width: 150px;
  padding: 15px 5px 5px 5px;
}

.suggestion-single p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 14px;
  margin-top: 10px;
  text-align: center;
}

.all-suggestion-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  width: 600px;
  overflow-x: auto;
}

.suggestion-single button.red-btn:hover {
  border: 1px solid var(--red);
}

.suggestion-single button.red-btn {
  height: 34px;
}

.Suggestion-section-main {
  padding: 15px 0;
  border-top: 1px solid #d3d2d2;
  border-bottom: 1px solid #d3d2d2;
  margin-top: 15px;
  margin-bottom: 15px;
}

.home-main::-webkit-scrollbar,
.register-signin-main::-webkit-scrollbar,
.sign-in-main::-webkit-scrollbar {
  display: none;
}

.page-top-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d2d2;
}

.top-menu-items {
  min-width: 33px;
  max-width: 33px;
  min-height: 33px;
  max-height: 33px;
  border-radius: 50%;
  background: var(--blackdiff);
  position: relative;
  cursor: pointer;
}
.top-menu-items img {
  width: 22px;
  height: 22px;
  object-fit: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.top-menu-items:hover {
  background: var(--red);
}
.page-top-section-right {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.header-right-main input {
  color: var(--white);
}

.page-top-section-right input {
  color: var(--blackdiff) !important;
}

.page-top-section {
  margin-bottom: 20px;
}
.card.FindClubCard-main {
  max-width: 295px;
  position: relative;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}

.add-wishlist {
  border-radius: 58px;
  background: rgba(41, 31, 31, 0.2);
  backdrop-filter: blur(10px);
  width: 28px;
  height: 28px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.add-wishlist:hover,
.add-wishlist.active {
  background: var(--white);
}

.add-wishlist:hover svg,
.add-wishlist.active svg {
  fill: var(--red);
}

.card-img-top {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 196px;
  object-fit: cover;
}

.card-title h6 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-title p:first-child {
  text-decoration: line-through;
}

.card-title p {
  color: #cccccc;
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: strikethrough;
}

.card-title h4 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.card-title p:last-child {
  color: var(--white);
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 136px;
  border-radius: 0px 0px 10px 10px;
  background: rgba(41, 31, 31, 0.15);
  backdrop-filter: blur(6px);
  width: 100%;
  padding: 10px;
}

.card-title-right {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.card-body {
  padding: 0;
}

.find-club-card-timing {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  margin-top: 10px;
  max-width: 273px;
  overflow-x: auto;
}

/* .find-club-card-timing::-webkit-scrollbar {
  display: none;
} */

.find-club-card-timing span,
.find-club-card-timing a {
  padding: 6px 5px;
  border-radius: 5px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  cursor: pointer;
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: 84px;
}

.find-club-card-timing span.not-available,
.find-club-card-timing a.not-available {
  border-width: 1px 0 0 0;
}

.card-text {
  padding: 10px;
}

.find-clubs-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 30px;
  margin-top: 30px;
}
.find-clubs-wrapper.data-not-found {
  display: block;
}
.approx-person p {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.find-clubs-wrapper .card {
  height: fit-content;
}
.approx-person {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  border-radius: 58px;
  border: 1px solid #f6f7f9;
  background: rgba(41, 31, 31, 0.3);
  backdrop-filter: blur(10px);
  width: 155px;
  position: absolute;
  bottom: 93%;
  left: 0;
  padding: 7px 10px;
}

.transparent-btn {
  border-radius: 5px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  backdrop-filter: blur(10px);
  background: transparent;
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

p.disabled,
div.disabled {
  opacity: 0.6;
}

span.disabled {
  opacity: 0.6;
  cursor: unset;
  display: flex;
  align-items: center;
  grid-gap: 5px;
  justify-content: center;
}

.find-club-card-timing span:not(.disabled):hover,
.find-club-card-timing a:not(.disabled):hover {
  color: var(--red);
}

.plan-header {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #fff;
}

.plan-name p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.plan-name h6 {
  color: var(--red);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.plan-detail-single {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header-search-group .search-input-group {
  width: 100% !important;
  background: var(--white);
  border-color: #e2e2e2;
}

.modal-header-search-group {
  width: 400px;
}

.modal-header-search-group input {
  color: var(--blackdiff) !important;
}

.black-search-icon {
  filter: brightness(0);
}

.find-club-filter-wrapper h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.find-club-filter-inner {
  margin-bottom: 20px;
}

.dropIn-court input:checked {
  background-color: #38a505 !important;
  border-color: #38a505;
}

.dropIn-court input {
  border: 1px solid #38a505 !important;
  width: 20px;
  height: 20px;
}

.dropIn-court label {
  color: var(--blackdiff) !important;
}

.dropIn-court .form-check {
  align-items: center !important;
}

.dropIn-court {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.very-small-text {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dark-color {
  color: var(--blackdiff);
}

.modal-buttons .white-btn:hover {
  border: 1px solid var(--red);
}

.modal-buttons .red-btn:hover {
  border: 1px solid var(--red);
}

.btn-close:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.plan-detail-single h6 {
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.plan-detail-single h6 span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.circular-checkbox .form-check-input {
  border-radius: 50% !important;
  border: 1px solid var(--text-light) !important;
}

.circular-checkbox .form-check-input:checked {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
}

.red-btn.red-border:hover,
.red-btn.red-border:focus,
.red-btn.red-border:active {
  border: 1px solid var(--red) !important;
  background: var(--white) !important;
}

.breadcrumb-item::before {
  display: none;
}

.breadcrumb {
  margin-bottom: 0;
}

/* Table styles____________________________________ */

table thead tr th:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

table thead tr th:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

table th {
  background-color: var(--headerColor) !important;
  color: #fff;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  padding: 12px 8px !important;
  vertical-align: middle;
}

table tbody td {
  border-bottom: 1px solid #e1e1e3;
  color: var(--headerColor);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  vertical-align: middle;
}

table tbody tr:last-child td {
  border-bottom: none;
}

tbody td span {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

table thead * {
  border-bottom-width: 0;
}

table .red-btn {
  height: 34px;
  font-size: 14px;
}

.profile-image-in-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #66666650;
}

.star-icon-in-table {
  width: 18px;
  height: 16px;
  object-fit: cover;
}

.sticky-th {
  position: sticky;
  right: 0;
  background: var(--headerColor);
}

.breadcrumb-item {
  color: rgba(41, 31, 31, 0.5);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.breadcrumb-item.active {
  color: var(--blackdiff);
}

.booking-date {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  border-radius: 30px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  padding: 5px 12px;
}

.booking-date-and-time {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.booking-date p {
  color: rgba(41, 31, 31, 0.8);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.booking-court-type p:not(:first-child) {
  padding-left: 5px;
}

.booking-court-type p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.booking-court-type {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.court-and-court-name p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.court-and-court-name h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18) !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  margin-bottom: 5px !important;
}

.court1Image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.court1Image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.my-booking-main .courtContent {
  width: auto;
}
.my-single-booking {
  display: flex;
  grid-gap: 15px;
  padding: 10px;
  border-radius: 10px;
  background: var(--white);
  height: fit-content;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}

.booking-status-badge p {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.booking-status-badge {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 10px;
  padding: 5px 10px;
  background: #38a505;
}

.club-detail-content-wrapper h3 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.club-detail-place {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
}

.club-detail-detail {
  color: #524a4b;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 15px;
}

.club-detail-costing h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.club-detail-costing p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.club-detail-costing span {
  color: #aaaaaa;
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
}

.club-detail-content-wrapper {
  position: relative;
}

.club-detail-costing {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.club-detail-img {
  margin-bottom: 15px;
}

.club-detail-stars {
  display: flex;
  align-items: center;
  grid-gap: 4px;
}

.club-detail-stars svg {
  cursor: pointer;
}

.available-courts-detail-main {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
}

.available-courts-columns:nth-child(2) .single-amanity-img {
  background: #ffd2d2;
}

.available-courts-columns:nth-child(3) .single-amanity-img {
  background: #dce6ff;
}

.single-amanity {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.single-amanity-img {
  border-radius: 50%;
  background: #fde5c8;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.red .single-amanity-img {
  background: #ffd2d2;
}
.blue .single-amanity-img {
  background: #dce6ff;
}
.single-amanity-img img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.available-courts-amanities {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 15px;
}

.single-amanity p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.available-courts-detail-main h6 {
  color: var(--red);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 15px;
}

.available-courts-detail-main {
  margin-top: 30px;
}

.approx-people-on-court {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  border-radius: 12px;
  background: linear-gradient(180deg, #ef333f 4.75%, #bd000c 92.93%);
  padding: 12px;
  margin-top: 20px;
}

.approx-people-on-court p {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.club-detail-days-and-time-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.club-detail-days-and-time-single:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.club-detail-days-and-time-single:not(:first-child) {
  padding-top: 15px;
}

.club-detail-days-and-time-single p,
.club-detail-days-and-time-single span {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.club-detail-days-and-time-single span {
  color: var(--text-light);
}

.club-detail-opening-hours h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.club-detail-opening-hours {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin-top: 20px;
}

.club-detail-map-top p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.club-detail-map-top {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}

.club-detail-map-top-image {
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  border: 1px solid rgba(41, 31, 31, 0.05);
  background: #291f1f;
}

.club-detail-map-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 60px;
  margin-bottom: 20px;
}

.club-detail-map-section {
  margin-top: 20px;
  padding: 20px 0;
  border: 1px solid #d9d9d9;
  border-width: 1px 0 1px 0;
}

.club-detail-map-bottom {
  border-radius: 20px;
  background: lightgray -2.916px -423.246px / 100.399% 360.928% no-repeat;
  width: 100%;
  height: 320px;
}

.two-players-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.single-players-section {
  width: 100%;
  margin-bottom: 10px;
}

.single-players-section img {
  border-radius: 50%;
  width: 38px;
  height: 38px;
  object-fit: cover;
  border: 1px solid #66666650;
}

.club-detail-recent-result-main h3 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}

.team-name {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.recent-result-scores-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d9d9d9;
  padding-top: 10px;
}

.recent-result-scores-wrapper h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-24);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 12px;
}

.recent-result-scores-wrapper h6:not(:last-child) {
  border-right: 1px solid #d9d9d9;
}

.recent-result-wrapper {
  display: flex;
  /* align-items: center; */
  grid-gap: 6px;
  background: url("../images/scoreBg.jpg") no-repeat;
  background-size: cover;
  border-radius: 10px;
  padding: 5px;
  z-index: 1;
  width: 100%;
}

.recent-result-single-team {
  padding: 10px 5px 5px 5px;
  border-radius: 8px;
  background: var(--white);
  width: 100%;
  height: fit-content;
}

.single-players-section p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 15px;
}

.single-players-section h6 {
  color: var(--blackdiff);
  font-family: var(--blackdiff);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.all-results-main::-webkit-scrollbar {
  display: none;
}

.all-results-main {
  overflow-x: auto;
}

.recent-result-date-and-time p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
}

.recent-result-date-and-time {
  padding: 10px;
}

.recent-result-wrapper-main {
  border-radius: 10px;
  background: var(--white);

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  z-index: -1;
}

.club-detail-main h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}

.book-court-section {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
}

.book-court-calendar {
  padding-bottom: 20px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 25px;
}

.form-switch .form-check-input {
  width: 45px !important;
  height: 28px !important;
  background-color: #a4a4a4;
  border-color: #a4a4a4 !important;
}

.form-switch .form-check-input:checked {
  background-color: #38a505 !important;
  border-color: #38a505 !important;
}

.form-switch .form-check-input:checked[type="checkbox"] {
  background-image: url("../images/swichbg.svg") !important;
  background-size: 22px 22px;
}

.form-switch .form-check-input {
  background-image: url("../images/swichbg.svg") !important;
  background-size: 22px 22px;
}

.club-detail-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 415px;
  border-radius: 10px;
}

.form-switch .form-check-input:focus {
  background-image: url("../images/swichbg.svg") !important;
  background-size: 22px 22px;
}

.show-available-slots-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-available-slots-section h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.club-detail-right-fixed-width {
  max-width: 490px;
  margin-left: auto;
}

.legends-of-available-slots-single {
  display: flex;
  align-items: center;
  grid-gap: 7px;
}

.legends-of-available-slots-single > div {
  border-radius: 3px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #ffeced;
  backdrop-filter: blur(10px);
  width: 18px;
  height: 18px;
}

.legends-of-available-slots {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  margin-top: 16px;
  margin-bottom: 28px;
}

.legends-of-available-slots-content p:not(.slots-out-of-all p) {
  border-radius: 5px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #fff;
  backdrop-filter: blur(10px);
  padding: 15px 20px;
  text-align: center;
}

.legends-of-available-slots-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 20px;
}

.legends-of-available-slots-inner-para {
  position: relative;
}

.slots-out-of-all {
  border-radius: 58px;
  border: 1px solid #f6f7f9;
  background: rgba(41, 31, 31, 0.4);
  backdrop-filter: blur(2px);
  padding: 4px 10px;
  max-width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 6px;
  position: absolute;
  top: -15px;
  right: 30%;
}

.slots-out-of-all img {
  width: 14px;
  height: 14px;
}

.slots-out-of-all p {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
}

.show-available-courts-only-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.show-available-courts-only-section-header h5 {
  margin-bottom: 0;
}

.red-border:hover {
  border: 1px solid var(--red);
}

.show-available-courts-only-content-single {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.show-available-courts-only-content-single:last-child {
  padding-top: 15px;
}

.show-available-courts-only-content-single:not(:last-child) {
  padding: 15px 0;
  border-bottom: 1px solid #d9d9d9;
}

.show-available-courts-only-content-single button {
  max-height: 40px;
  min-width: 100px;
}

.show-available-courts-only-content-single h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-bottom: 5px; */
}

.show-available-courts-only-content-single span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.club-detail-activity-tabs .nav-tabs .nav-link.active {
  border-width: 0 0 1px 0;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.club-detail-activity-tabs .nav-tabs {
  justify-content: center;
}
.club-detail-activity-tabs .nav-tabs li {
  width: 50%;
  text-align: center;
}
.club-detail-activity-tabs .nav-tabs li button {
  width: 100%;
  text-align: center;
}
.club-detail-activity-tabs .nav-tabs .nav-link {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.club-detail-activity-tabs .nav-tabs .nav-link.active,
.club-detail-activity-tabs .nav-tabs .nav-link:hover {
  border-width: 0 0 2px 0;
  border-color: var(--red);
}

.legends-of-available-slots-inner-para:not(
    .disabled.legends-of-available-slots-inner-para
  ) {
  cursor: pointer;
  width: 146px;
}

.club-detail-activity-add-buttons button {
  padding: 8px 12px;
  border-radius: 50px;
  background: #291f1f;
  border: none;
}

.club-detail-activity-add-buttons button span {
  color: var(--white);
  text-align: right;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.club-detail-activity-add-buttons button img {
  width: 16px;
  height: 16px;
}

.club-detail-activity-add-buttons {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.club-detail-activity-section {
  margin-top: 25px;
}

.activity-section-bottom-single {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 5px;
}

.activity-section-bottom-single-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #f6f7f9;
  padding: 5px 7px;
}

.activity-section-bottom-single-top p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.activity-section-bottom-single-top span {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.activity-section-bottom-single-bottom-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #66666650;
}

.activity-section-bottom-single-bottom-image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.activity-section-bottom-single-bottom {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  margin-top: 10px;
  padding: 0 7px 10px 7px;
}
.activity-section-bottom {
  max-height: 550px;
  overflow-y: auto;
}
.date-picker-button-main {
  display: flex;
  align-items: center;
  grid-gap: 8px;
  border-radius: 6px;
  background: #ef333f;
  padding: 6px 10px;
  cursor: pointer;
}

.date-picker-button-main span {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.find-club-filter-datepicker-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.find-club-filter-datepicker-main .react-datepicker__input-container input {
  min-width: 225px;
}

.react-datepicker__input-container input {
  border: 1px solid var(--text-color);
  color: var(--blackdiff);
  font-family: var(--Outfit);
  line-height: normal;
  font-size: 14px;
  border-radius: 10px;
  padding: 10px;
}

.find-club-filter-datepicker-main p {
  font-size: 12px;
  line-height: normal;
  font-family: var(--Outfit);
  color: var(--blackdiff);
  margin-bottom: 5px;
  font-weight: 500;
}

.react-datepicker__input-container:after {
  content: "";
  position: absolute;
  background: url("../images/calendarTemp.svg") no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  top: 27%;
  right: 10px;
}

.filter-trans-btn {
  border-radius: 5px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: var(--white);
  padding: 8px 15px;
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter-trans-btn:hover,
.filter-trans-btn.active {
  border: 1px solid var(--red);
  background: var(--red);
  color: var(--white);
}

.sort-by-find-club-wrapper {
  position: relative;
  border-radius: 5px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #eeeeee;
  padding: 8px 15px;
}

.sort-by-find-club-wrapper span {
  position: absolute;
  bottom: 70%;
  right: -8px;
  cursor: pointer;
}

.sort-by-find-club-wrapper span img {
  filter: brightness(0);
  width: 16px;
  height: 16px;
}

.sort-by-find-club {
  font-size: 16px;
  line-height: 100%;
  font-family: var(--Outfit);
  color: var(--blackdiff);
}

.all-sort-by-main,
.page-top-section-left-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}

.top-menu-items.top-menu-item-diff {
  background: #eeeeee;
}

.top-menu-items.top-menu-item-diff img {
  filter: brightness(0);
}

.top-menu-items.top-menu-item-diff.active {
  background: var(--red);
}

.top-menu-items.top-menu-item-diff.active img {
  filter: brightness(15);
}

.FindClubCard-main .legends-of-available-slots-inner-para {
  max-width: 100px;
  margin-top: 5px;
}

.FindClubCard-main .slots-out-of-all p {
  font-size: 10px;
}

.FindClubCard-main
  .legends-of-available-slots-content
  p:not(.slots-out-of-all p) {
  padding: 10px 5px;
  font-size: 13px;
}

.FindClubCard-main .slots-out-of-all {
  right: 13%;
}

.legends-of-available-slots-content {
  max-width: 273px;
  margin-top: 10px;
}

.legends-of-available-slots-content::-webkit-scrollbar {
  display: none;
}

.time-slot-title p {
  color: var(--blackdiff);
}

.activity-section-bottom-single:not(:last-child) {
  margin-bottom: 10px;
}

.activity-section-bottom-date-picker-inner {
  border-radius: 30px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  padding: 5px 10px;
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.activity-section-bottom-date-picker {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin-top: 10px;

  margin-bottom: 10px;
}

.table-profile-img {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.table-profile-img img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.all-action-main {
  display: flex;
  align-items: center;
}

.all-action-main img {
  cursor: pointer;
  height: 22px;
  width: 22px;
  object-fit: cover;
}

.all-action-main img:not(:first-child) {
  margin-left: 10px;
}

.filter-dark {
  filter: brightness(0);
}

.activity-score-points-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  border-top: 1px solid #eae9e9;
}

.activity-score-points-stars {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}

.activity-section-bottom-single-bottom-content {
  width: calc(100% - 70px);
}

td.week_cell {
  border: 1px solid rgba(41, 31, 31, 0.1);
  border: none;
  text-align: center;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 15px 5px;
  border-radius: 50%;
  cursor: pointer;
}

td.week_cell.activDate {
  background: linear-gradient(180deg, #ef333f 37.5%, #bd000c 100%);
  color: var(--white);
}

.calender_body th {
  background-color: unset !important;
}

th.week_cell {
  color: var(--blackdiff) !important;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-top: 5px;
}

.next_prev_align {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 50px;
  margin-bottom: 30px;
}

button.prev.btn_css {
  border: none;
  background: transparent;
}

button.prev.btn_css svg {
  fill: var(--blackdiff);
}

button.next.btn_css {
  border: none;
  background: transparent;
}

button.next.btn_css svg {
  fill: var(--blackdiff);
}

.calender_main .calender_body table {
  width: 100%;
}

.cart-title {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 18px;
}

.cart-page-left-title h5 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cart-page-left-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 15px 20px;
  background: #291f1f;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
}

.cart-profile-image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.cart-profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.cart-profile-content p:not(:last-child) {
  margin-bottom: 5px;
}

.cart-profile-content p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cart-profile-content h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 4px;
}

.cart-profile-content h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}

.cart-profile-content span {
  color: #666;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}

.cart-page-left-content-single {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.cart-profile-image-content {
  display: flex;
  align-items: flex-start;
  grid-gap: 20px;
}

.cart-page-left-content-single:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid #bfbcbc;
  margin-bottom: 15px;
}

.cart-page-left-content {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 15px 15px;
}

.summary-inner-sub-total p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.summary-inner-sub-total p:last-child {
  font-weight: 600;
}

.summary-inner-sub-total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #bfbcbc;
}

.cancellation-policy p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.cancellation-policy p.red-text {
  font-weight: 500;
}

.cancellation-policy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #f6f7f9;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 100px;
}

.cart-page-right-main h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart-page-right-main {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 15px;
  margin-left: 20px;
  /* min-height: 370px; */
}

.deleteIcon {
  cursor: pointer;
}

.enter-promo-code-main .input-group {
  border-radius: 70px;
  background: #f6f7f9;
  margin-bottom: 15px;
}

.cart-page-payment-method {
  margin-top: 25px;
}

.cart-page-payment-inner:not(:last-child) {
  border-bottom: 1px solid #bfbcbc;
}

.cart-page-payment-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 15px;
}

.cart-page-payment-inner-img img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.cart-page-payment-inner-img {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.enter-promo-code-main input {
  color: var(--blackdiff) !important;
}

.payment-success-main .sign-in-inner-main {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 359px;
  background: url("../images/successbg.svg") no-repeat;
  background-size: contain;
  border-radius: 23px;
  /* mix-blend-mode: luminosity; */
}

.payment-success-main .sign-in-inner-main {
  position: relative;
}
.payment-success-image {
  text-align: center;
  margin-bottom: 25px;
}

.payment-success-content p {
  color: #eeeeee;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 10px;
}
p.payment-success-para {
  margin-bottom: 60px;
}
.payment-success-content h5 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.payment-success-content h4 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.payment-success-main .sign-in-inner-main {
  min-height: 630px;
}
.payment-success-bottom-part {
  text-align: center;
  margin-top: 20px;
}
.payment-success-bottom-part p {
  color: #eeeeee;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 25px;
}
.payment-success-bottom-part button {
  width: 500px;
}
.payment-success-bottom-part img {
  cursor: pointer;
}
.courtContent .activity-score-points-wrapper {
  margin-top: 15px;
}

.twoInOneBooking {
  grid-gap: 15px;
}
.all-my-bookings {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.paggination span a.paginate_button {
  border-radius: 3px !important;
  border: 1px solid #e1e1e3 !important;
  background: var(--white) !important;
  color: var(--blackdiff) !important;
  font-family: var(--Outfit);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 2px 9px !important;
}
.paggination span a.paginate_button.current {
  border: 1px solid var(--red) !important;
  background: var(--red) !important;
  color: var(--white) !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: var(--white) !important;
}
/* a.paginate_button.previous {
  background-image: url("../images/blackLeftArrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  width: 18px !important;
  height: 18px !important;

} */
.paggination span a.paginate_button:hover {
  border: 1px solid var(--red) !important;
  background: var(--red) !important;
  color: var(--white) !important;
}
.booking-detail-image-content {
  margin-top: 20px;
}
.booking-detail-location-email-call-bg img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.booking-detail-location-email-call-bg {
  border-radius: 50%;
  border: 1px solid rgba(41, 31, 31, 0.05);
  background: #eeeeee;
  padding: 12px;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.booking-detail-location-email-call {
  display: flex;
  align-items: center;
  grid-gap: 60px;
  margin-top: 20px;
}
.booking-detail-location-single {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.booking-detail-right-top-image img.add-icon {
  background: var(--headerColor);
}
.booking-detail-right-top-image img {
  text-align: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: scale-down;
}
.booking-detail-image-main img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.booking-detail-right-top-single:not(:last-child) {
  margin-bottom: 15px;
}
.booking-detail-right-top-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.booking-detail-right-main-wrapper h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.booking-detail-right-top,
.booking-detail-right-bottom {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
}
.booking-detail-right-main-wrapper {
  margin-left: 20px;
  max-width: 480px;
}
.booking-detail-right-top-single p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.booking-detail-right-top-single span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.booking-detail-right-bottom-single span {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.booking-detail-right-bottom-single p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.booking-detail-right-bottom-single {
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.booking-detail-right-bottom-single:not(:last-child) {
  border-bottom: 1px solid #eae9e9;
}
.booking-detail-right-bottom-single:last-child {
  margin-bottom: 0;
}
.red-bordered-btn {
  border-radius: 10px;
  border: 1px solid #f5e7ea;
  background: #f5e7ea;
  color: var(--red);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  border: 1px solid var(--red);
  line-height: normal;
  height: 50px;
}
.red-bordered-btn:hover {
  background: var(--red);
  color: var(--white);
}
.booking-detail-right-bottom {
  margin-top: 20px;
}
.booking-detail-location-email-call-bg:hover {
  background: var(--text-color);
}
.booking-detail-image-content {
  position: relative;
}
span.green-badge {
  border-radius: 10px;
  background: #38a505;
  color: var(--white) !important;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 14px;
}
span.red-badge {
  border-radius: 10px;
  background: var(--red);
  color: var(--white) !important;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 14px;
}
span.dark-badge {
  border-radius: 10px;
  background: var(--headerColor);
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 14px;
}
span.grey-badge {
  border-radius: 10px;
  background: #e0e0e0;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 7px 14px;
}
/* span.dark-badge */
.profile-detail-activity-badge span.green-badge,
.profile-detail-activity-badge span.red-badge {
  border-radius: 30px;
  font-size: 12px;
  padding: 5px 12px;
}
.booking-detail-image-content span.dark-badge,
.booking-detail-image-content span.grey-badge,
.booking-detail-image-content span.yellow-badge,
.booking-detail-image-content span.green-badge,
.booking-detail-image-content span.red-badge {
  position: absolute;
  top: 0;
  right: 0;
}
.booking-detail-cancellation-policy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #f6f7f9;
  padding: 10px 12px;
  margin-bottom: 10px;
}
.booking-detail-cancellation-policy p,
.booking-detail-cancellation-policy span {
  color: var(--red);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.booking-detail-cancellation-policy p {
  font-weight: 400;
  color: var(--blackdiff);
}
.booking-detail-right-bottom {
  max-height: 480px;
  overflow-y: auto;
}
.booking-detail-right-bottom-button {
  position: sticky;
  bottom: 0;
}
.booking-detail-right-bottom::-webkit-scrollbar {
  display: none;
}
.booking-detail-right-middle-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
}
.booking-detail-right-middle-top h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.booking-detail-right-middle .recent-result-wrapper {
  background-size: cover;
}
.booking-detail-right-middle .recent-result-scores-wrapper h6 {
  margin-bottom: 0;
  padding: 0 30px;
}

.booking-detail-right-middle .recent-result-single-team {
  width: 100%;
}
.booking-detail-right-top-image img.completed-profile-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
.page-top-section-right-single-wrapper p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.page-top-section-right-single-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 6px;
  background: var(--white);
  padding: 8px 12px;
  border-radius: 50px;
  cursor: pointer;
}
.rate-court-main .booking-detail-image-main {
  width: 70px;
  height: 70px;
}
.rate-court-ratings h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.upload-score-inner h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.upload-score-datepicker-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.UploadScoreModalMainWrapper .modal-dialog {
  max-width: 620px;
}
.upload-score-datepicker-wrapper .react-datepicker__input-container input,
.upload-score-datepicker-wrapper .react-datepicker__input-container {
  width: 285px;
}
.react-datepicker__input-container input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
}
.upload-score-datepicker-wrapper p,
.upload-score-duration p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}
.duration-inner {
  border-radius: 40px;
  background: #f6f7f9;
  padding: 10px 22px;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  border: 1px solid transparent;
}
.duration-inner.active,
.duration-inner:hover {
  background: var(--headerColor);
  color: var(--white);
  border: 1px solid var(--headerColor);
}
.all-duration-main {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.red-radio-btn .form-check-label {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.red-radio-btn {
  display: flex;
  align-items: center;
}
.red-radio-btn .form-check {
  align-items: center !important;
}
.upload-score-duration .red-radio-btn {
  margin-top: 20px;
}
.red-radio-btn .form-check:not(:last-child) {
  padding-right: 20px;
  border-right: 1px solid #eae9e9;
  margin-right: 20px;
}
.red-radio-btn .form-check-input:checked[type="radio"] {
  background-image: url("../images/redDot.svg") !important;
  border-color: var(--red) !important;
}
.enter-score-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.enter-score-inner .enter-score-inner-profile-single {
  display: flex;
  grid-gap: 5px;
  align-items: center;
}
.enter-score-inner-profile-score {
  display: flex;
  align-items: center;
  grid-gap: 15px;
}
.enter-score-inner-profile-single:first-child {
  margin-bottom: 10px;
}
.enter-score-inner-profile-score p {
  border-radius: 6px;
  border: 1px solid rgba(41, 31, 31, 0.15);
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-24);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.enter-score-inner-input {
  margin-bottom: 10px;
}
/* .enter-score-inner-input input {
  height: 40px;
} */
.upload-score-enter-score-main h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 20px;
  margin-bottom: 15px;
}
.black-colored-focused.form-control:focus,
.black-colored-focused.form-select:focus {
  color: var(--blackdiff) !important;
  background-color: var(--white) !important;
  border: 1px solid rgba(41, 31, 31, 0.15) !important;
  outline: none;
  box-shadow: none !important;
}
.form-control,
.form-select {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
}
.enter-score-inner-profile-single img,
.enter-score-inner-profile-two-person img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
}
.enter-score-inner-profile-single p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.enter-score-inner-profile-single span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.enter-score-inner-main {
  background: url("../images/scoreBg.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  padding: 5px;
  border-radius: 10px;
}
.enter-score-inner-main:not(:last-child) {
  margin-bottom: 10px;
}
.enter-score-inner-wrapper {
  background: var(--white);
  padding: 5px;
  border-radius: 8px;
}
.modal-body {
  max-height: 560px;
  overflow-y: auto;
}
.settings-aside-main {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  max-width: 400px;
  position: sticky;
  left: 0;
  top: 96px;
  height: calc(100vh - 96px);
  overflow-y: auto;
  /* max-height: 780px; */
}
.settings-aside-main::-webkit-scrollbar {
  display: none;
}
.settings-aside-single {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
}
.settings-aside-single p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.settings-aside-single.active p {
  color: var(--blackdiff);
  font-weight: 600;
}
.general-settings-main ul li {
  margin-bottom: 15px;
}
.settings-aside-single.active img {
  filter: brightness(0);
}
.general-settings-main ul li:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}
.profile-setting-main h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 20px;
}
.settings-right-side-main {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 20px;
  margin-left: 20px;
  max-height: calc(100vh - 96px);
  /* max-height: 650px; */
  overflow-y: auto;
  margin-bottom: 30px;
}
.settings-right-side-main::-webkit-scrollbar {
  display: none;
}
img.edit-profile-image {
  width: 79px;
  height: 79px;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid var(--red);
  margin-bottom: 20px;
  object-fit: cover;
}
.book-court-calendar-day-date {
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-gap: 8px;
}
.book-court-calendar-day-date-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  overflow-y: auto;
}
.book-court-calendar-day-date-wrapper::-webkit-scrollbar {
  display: none;
}
.book-court-calendar-day-date .date-number.active {
  background-color: var(--red);
  color: var(--white);
  border-color: var(--red);
}
.book-court-calendar h4 {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}
.book-court-calendar-day-date .date-number {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.book-court-month-arrows {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 50px;
  margin-bottom: 20px;
}
span.date-day {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.show-available-courts-only-section-header {
  margin-bottom: 15px;
}
.show-available-courts-only-section-header h4 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.see-all-participants p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  cursor: pointer;
}
.see-all-participants {
  display: flex;
  align-items: center;
  /* grid-gap: 10px; */
}
.see-all-participants img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  filter: brightness(3);
}
.dark-badge-item {
  background: var(--blackdiff);
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 12px;
  border-radius: 90px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.dark-badge-item-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin-bottom: 15px;
}
.players .players-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.players-inner-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.players-inner-wrapper-content h6:first-child {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.players-inner-wrapper-content h6:last-child {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.players .players-inner-wrapper {
  margin-bottom: 15px;
}
.join-btn-and-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.join-btn-and-price-price {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.join-btn-and-price button {
  height: 40px;
}
.edit-profile-inputs {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
}
.edit-profile-inputs .react-datepicker__input-container input {
  width: 100%;
}
.gender-input h5 {
  margin-bottom: 10px;
}
.edit-profile-save {
  margin-top: 40px;
}
.post-following-followers-wrapper span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.post-following-followers-wrapper h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.post-following-followers-wrapper {
  display: grid;
  align-items: center;
  margin-top: 25px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.post-following-followers-inner {
  border-radius: 15px;
  background: #f6f7f9;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}
.profile-detail-page-right-inner img.profile-detail-page-right-inner-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 1px;
  border: 2px solid var(--red);
  object-fit: cover;
}
img.profile-detail-page-right-inner-image.profile-detail-page-right-inner-image-golden {
  border: 2px solid #ea8c00;
}
.profile-detail-page-right-inner h4 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 10px;
  position: relative;
}
span.profile-after-initials {
  color: var(--white) !important;
  font-family: var(--Outfit);
  font-size: 10px !important;
  border-radius: 60px;
  background: var(--blackdiff);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 66%;
  bottom: 35%;
  padding: 1px 6px;
}
.profile-detail-page-right-inner p {
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--red);
}
.profile-detail-page-right-inner span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile-detail-page-right {
  border-radius: 20px;
  position: relative;
  background: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 30px 15px 15px 15px;
  text-align: center;
  margin-left: 20px;
  position: sticky;
  top: 96px;
}
/* .level-information-on-badge{
  position: relative;
  display: inline-block;
  max-width: 26px;
} */

.level-information-on-badge h5 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: normal;
}
.level-information-on-badge p {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: 10px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: normal;
}
.level-information-on-badge {
  background: url("../images/redstar.jpg") no-repeat center center;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 15px;
}
.gray.show-available-courts-only-content-single h6,
.gray.show-available-courts-only-content-single span {
  color: var(--text-light);
}
.gray.show-available-courts-only-content-single button {
  background: #a4a4a4;
  color: var(--blackdiff);
  border-color: #a4a4a4;
}
.gray.show-available-courts-only-content-single button:hover,
.gray.show-available-courts-only-content-single button:focus {
  background: #a4a4a4 !important;
  color: var(--blackdiff) !important;
  border-color: #a4a4a4 !important;
  cursor: auto;
}
.legends-of-available-slots-inner-para.active p:not(.slots-out-of-all p) {
  background: var(--red) !important;
  color: var(--white);
}
.cart-confirmation-page-main .deleteIcon {
  display: none;
}
.circular-checkbox .form-check-input:checked[type="checkbox"] {
  background-image: url("../images/white-tick.png") !important;
}
.date-and-time-container p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.date-and-time-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.date-and-time-container .react-datepicker-wrapper,
.date-and-time-container .react-datepicker-wrapper input {
  width: 225px;
}
.date-and-time-container .react-datepicker-wrapper input {
  background: #f6f7f9;
}
.AddtocartmodalMain .modal-body,
.court-ratings-modal-main .modal-body,
.create-post-modal-main .modal-body {
  min-height: 460px;
}
.gray-bg-input.form-control {
  background: #f6f7f9 !important;
}
.interestedcourt-inner .card {
  border-radius: 10px;
  width: 100%;
}
.interestedcourt-inner .card-img-top {
  height: 194px;
}
.invite-friends-btn button {
  background: none !important;
  border: none !important;
  height: 50px;
  width: 50px;
}
.invite-friends-btn button img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.invite-friends-btn button:focus,
.invite-friends-btn button:hover,
.invite-friends-btn button:active {
  background: none !important;
  border: none !important;
  outline: none;
  box-shadow: none;
}
.invite-friends-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.interestedcourt-wrapper h5,
.invite-friends-btn h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.invite-friends-single:not(:last-child) {
  margin-bottom: 15px;
}
.invite-friends-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invite-friends-single-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid #66666650;
}
.invite-friends-single-img-name {
  display: flex;
  align-items: center;
  grid-gap: 12px;
}
.invite-friends-main .search-input-group {
  border: none;
  border-radius: 0;
  background: #f6f7f9;
  margin-bottom: 20px;
}
.invite-friend-modal-main-wrapper .modal-footer {
  height: 82px;
}
.invite-friend-modal-main-wrapper .modal-body {
  min-height: 560px;
}
.add-no-of-people-main .form-control::-webkit-inner-spin-button {
  display: none;
}
.add-no-of-people-main .form-control {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: 54px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-width: 0 0 2px 0 !important;
  border-radius: 0;
}
.add-no-of-people-main .black-colored-focused.form-control:focus,
.add-no-of-people-main .black-colored-focused.form-select:focus {
  border-width: 0 0 2px 0 !important;
  border-radius: 0;
}
.two-red-radio-btn .red-radio-btn:not(:last-child) {
  padding-right: 20px;
  border-right: 1px solid #d2d0d0;
  margin-right: 20px;
}
.two-red-radio-btn {
  display: flex;
  align-items: center;
}
button.countr-code {
  height: 50px;
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.25);
  width: 85px;
  position: relative;
  padding-left: 0;
}
button.countr-code:hover,
button.countr-code:focus,
button.countr-code:active {
  border: 1px solid rgba(255, 255, 255, 0.25);
  outline: none;
  box-shadow: none;
}
button.countr-code::after {
  content: "";
  position: absolute;
  top: 15px;
  right: 5px;
  background-image: url("../images/downArrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
}
.invite-friends-main .search-input-group {
  border: none;
  border-radius: 0;
  background: #f6f7f9;
  margin-bottom: 20px;
}
.phone-code-modal-main .modal-body {
  min-height: 450px;
  padding: 0 0 16px 0 !important;
  background: #f6f7f9;
}
.phone-code-modal-main .invite-friends-main {
  position: sticky;
  top: 0;
}
.phone-code-modal-main .country-list {
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  margin: 15px;
  padding: 15px;
}
.find-club-date-select-modal-main .modal-body {
  min-height: 380px;
}
/* .find-club-filter-datepicker-main
  .find-club-filter-datepicker-single:last-child
  .react-datepicker-popper {
  transform: translate(235px, 109px) !important;
} */
.add-interest-profile-pic-name {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.add-interest-profile-pic-name p {
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--blackdiff);
}
.add-interest-profile-pic-name {
  margin-bottom: 15px;
}
.court-ratings-modal-inner-top {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  justify-content: center;
  padding: 15px;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
}
.court-ratings-modal-inner-top h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-24);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.court-ratings-modal-inner-top svg {
  width: 15px;
  height: 15px;
}
.court-ratings-modal-inner-top p,
.court-ratings-modal-inner-bottom-list-single span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}
.court-ratings-modal-bottom-list-img {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.court-ratings-modal-inner-bottom-list-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.court-ratings-modal-inner-bottom-list-single:not(:last-child) {
  margin-bottom: 15px;
}
.court-ratings-modal-inner-bottom-list-single p {
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--blackdiff);
}
.court-ratings-modal-inner-bottom {
  margin-top: 25px;
}
.settings-my-booking-main .my-single-booking {
  margin-bottom: 10px;
}
.courtContent {
  width: 90%;
}
.settings-my-booking-main .my-single-booking {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.my-single-booking.my-single-booking-with-bg {
  border-radius: 10px;
  border: 1px solid rgba(255, 129, 135, 0.1);
  background: #ffeced;
}
.my-single-booking:has(.pink-bg) {
  background: #ffeced !important;
}
.red-btn.red-border.disabled:hover {
  background: var(--text-color) !important;
  border-color: var(--text-color) !important;
  color: var(--blackdiff) !important;
}
.red-btn.red-border.disabled {
  background: var(--text-color);
  border-color: var(--text-color);
  color: var(--blackdiff);
}
.invite-friends-single .white-btn.btn:hover,
.invite-friends-single .white-btn.btn:active,
.invite-friends-single .white-btn.btn:focus,
.invite-friends-single .white-btn.btn:focus-visible {
  background: var(--white);
  color: var(--blackdiff);
  cursor: auto;
  border: 1px solid #d9d9d9;
  box-shadow: none;
}
.settings-memberships-inner .plan-header {
  border-radius: 8px;
  border: 1px solid #f6f7f9;
  background: #f6f7f9;
  padding: 10px;
}
.plan-header {
  position: relative;
}
.settings-memberships-inner .plan-body-main {
  padding: 0 10px 10px 10px;
}
.settings-memberships-inner .plan-body-main:last-child {
  padding-bottom: 0;
}
.plan-detail-single h6 {
  color: var(--red);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.plan-detail-single h6 span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.plan-detail-single {
  padding-top: 15px;
  padding-bottom: 15px;
}
.plan-body-main .plan-detail-single:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.plan-premium-main .dark-badge {
  padding: 10px 14px;
}
.membership-element-single p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  max-width: 310px;
}
.membership-element-single h4 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}
.membership-element-single {
  padding-bottom: 20px;
}
.settings-memberships-inner .plan-premium-main {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
}
.plan-detail-badge,
.membership-elements-all {
  padding: 0 10px;
}
.membership-elements-all {
  margin-top: 20px;
}
/* .settings-memberships-inner {
  max-height: 765px;
  overflow-y: auto;
} */
.settings-matches-scores-main .recent-result-wrapper {
  background-size: 100%;
}
.settings-matches-scores-main .single-players-section img {
  width: 50px;
  height: 50px;
}
.settings-matches-scores-main .recent-result-scores-wrapper h6 {
  padding: 0 30px;
}
.settings-matches-scores-inner-single:not(:last-child) {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #eae9e9;
}
.settings-matches-scores-inner-single .my-single-booking {
  box-shadow: none;
  padding: 0;
  margin-bottom: 20px;
}

#react-confirm-alert .react-confirm-alert-body h1 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}
#react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group {
  justify-content: space-between;
}
#react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group
  button {
  background: var(--red);
  color: var(--white);
  border: 1px solid var(--red);
  width: 100%;
  height: 40px;
}
#react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group
  button:first-child {
  background: var(--white);
  color: var(--red);
  border: 1px solid var(--red);
}
#react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group
  button:hover {
  background: var(--white);
  color: var(--red);
  border: 1px solid var(--red);
}
#react-confirm-alert
  .react-confirm-alert-body
  .react-confirm-alert-button-group
  button:first-child:hover {
  background: var(--red);
  color: var(--white);
  border: 1px solid var(--red);
}
.settings-wallet-header {
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(180deg, #ef333f 26.04%, #bd000c 100%);
  padding: 20px;
}
.settings-wallet-header .dark-badge {
  padding: 12px 26px;
  cursor: pointer;
}
.settings-wallet-header h5 {
  color: var(--white) !important;
  padding-bottom: 20px;
  border-bottom: 1px solid #ef4650;
  margin-bottom: 10px;
}
.settings-wallet-header h1 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.settings-wallet-header h3 {
  color: #f6f7f9;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
}
.settings-wallet-bot-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.settings-wallet-bot-header h5 {
  margin-bottom: 15px;
}
.settings-wallet-bot-content .my-single-booking {
  box-shadow: none;
}
.settings-wallet-bot-content .my-single-booking img {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  padding: 13px;
}
.settings-wallet-bot-content h2 {
  color: var(--red);
  text-align: right;
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.settings-wallet-bot-content-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eae9e9;
  cursor: pointer;
}
.settings-wallet-bot-content-single .courtContent {
  width: auto;
}
.settings-wallet-transaction-main {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  margin-bottom: 20px;
}
.settings-wallet-transaction-main h5 {
  margin-bottom: 0;
}
.settings-wallet-transaction-main img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.settings-wallet-bot-content {
  max-height: 550px;
  overflow-y: auto;
}
.settings-wallet-bot-content::-webkit-scrollbar {
  display: none;
}
.settings-wallet-transaction-content
  .settings-wallet-bot-content-single:first-child {
  padding-top: 0;
}
.homeDashboard-right-main {
  margin-left: 20px;
  position: sticky;
  top: 96px;
  max-height: calc(100vh - 96px);
  overflow: auto;
}
.homeDashboard-right-main::-webkit-scrollbar {
  display: none;
}
.homeDashboard-right-main h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.homeDashboard-right-main .see-all {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.homeDashboard-right-main .see-all:hover {
  color: var(--blackdiff);
}
.homeDashboard-right-main .activity-section-bottom-single-top {
  background: rgba(239, 51, 63, 0.08);
}
.activity-section-bottom-single-bottom p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  margin-bottom: 4px;
  line-height: normal;
}
.activity-section-bottom-single-bottom h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
}
.activity-section-bottom-single-bottom span {
  color: #524a4b;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.homeDashboard-right-main .activity-section-bottom-single-top {
  padding: 10px;
}
.homeDashboard-right-wrapper {
  margin-top: 15px;
}

.create-post-modal .form-select {
  background-size: 14px 14px !important;
  padding: 0 !important;
}
.create-post-modal .form-select:focus {
  border: none !important;
}
.create-post-write-something {
  margin-top: 20px;
}
.uploded-images-gallery img {
  border-radius: 8px;
  width: 60px;
  height: 60px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: var(--white);
  object-fit: scale-down;
}
.uploded-images-gallery {
  display: flex;
  /* align-items: center; */
  grid-gap: 10px;
  margin-top: 15px;
  flex-wrap: wrap;
}
.uploded-images-gallery-single {
  position: relative;
  cursor: pointer;
}
.uploded-images-gallery-single svg {
  position: absolute;
  left: 80%;
  bottom: 80%;
}
.create-post-upload h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.create-post-upload {
  margin-top: 20px;
}
.all-likes-wrapper h5.likes-title {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 25px;
}
.likes-signle {
  margin-top: 15px;
}
.all-likes-wrapper {
  padding-left: 15px;
  border-left: 1px solid #eae9e9;
}
.like-modal-inner-left {
  padding-right: 15px;
  position: sticky;
  top: 20px;
}
.likes-modal-main .modal-body {
  min-height: 650px;
  overflow-y: auto;
  position: relative;
}
.likes-modal-main .modal-body::-webkit-scrollbar {
  display: none;
}
.like-modal-inner-left .username-with-time h5 span {
  display: none;
}
.like-modal-inner-left .single-post-wrapper {
  box-shadow: none;
  padding: 0;
}
.like-modal-inner-left .post-section {
  max-width: 100%;
}
.all-likes-wrapper .search-input-group {
  border-color: #eae9e9;
}

.single-notification-single-image img,
img.comment-here-profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #66666650;
}
.single-notification-single-content span {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.single-notification-single-content h4 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.single-notification-single {
  display: flex;
  /* align-items: center; */
  grid-gap: 12px;
}
.all-comments-main .single-notification-single {
  align-items: unset;
}

.single-notification-single-name-hrs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.single-notification-single-wrapper:not(:last-child) {
  margin-bottom: 20px;
}
.comment-here-post-text {
  padding: 6px 25px 6px 12px;
  color: var(--white);
  cursor: pointer;
}
.write-comment-here-input-group {
  position: sticky;
  bottom: 0;
  background: var(--headerColor);
}
.write-comment-here-input-group input {
  color: var(--white) !important;
}
.write-comment-here {
  padding-top: 20px;
}
.all-comments-main {
  height: 470px;
  overflow-y: auto;
}
.all-comments-main::-webkit-scrollbar {
  display: none;
}
.add-balance-top h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.add-balance-top p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.add-balance-top {
  margin-bottom: 40px;
}
.add-balance-inner-main {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 10px;
}
.add-balance-inner-main h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.add-balance-inner-main .all-duration-main {
  margin-top: 15px;
}
.cancel-payment-icon {
  width: 22px;
  height: 22px;
  object-fit: contain;
}
.transaction-review {
  margin-top: 15px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #eae9e9;
  border-bottom: 1px solid #eae9e9;
}
.transaction-review h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.accept-reject-btns {
  display: flex;
  align-items: center;
  grid-gap: 15px;
  margin-top: 10px;
}
.all-comments-main .accept-reject-btns {
  display: none;
}
.unfollow-and-three-dots {
  display: flex;
  align-items: center;
  grid-gap: 20px;
}
.three-dots-btn {
  border: 1px solid #eae9e9;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  background: url("../images/threeDots.svg") no-repeat;
  background-position: center center;
}

.three-dots-btn img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.unfollow-and-three-dots {
  margin-top: 20px;
}
.unfollow-and-three-dots button.dropdown-toggle {
  background: none !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  height: 50px !important;
  width: 50px !important;
}
.unfollow-and-three-dots .dropdown-toggle::after {
  display: none !important;
}
.block-share-menu-item {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.block-share-menu-item p {
  color: var(--blackdiff);
}
.activity-section-bottom-diff-img {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
}
.activity-section-bottom-diff-img img {
  width: calc(100% - 24px);
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.sign-up-steps-opt button.active {
  background: var(--white) !important;
  color: var(--red) !important;
  border: 1px solid #d9d9d9 !important;
}
.tab-content > .active {
  margin: 15px 20px 15px 20px;
}
.all-followings .gray-btn.active {
  background: var(--red);
  color: var(--white);
  border: 1px solid var(--red);
}
.all-followings .gray-btn.active:hover {
  background: #f6f7f9;
  color: var(--blackdiff);
  border-color: #f6f7f9;
}
.rangeinfo {
  color: #291f1f;
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 8px 0;
}
.rangeslider {
  color: #291f1f;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 24px;
  text-align: center;
}
.rangeslider:not(:last-child) {
  margin-right: 42.57px;
}
.slidermain {
  border-radius: 38px;
  width: 100%;
  height: 24px;
  background: #eee;
}
.rnagenumber {
  padding: 0 0px;
}
.redrange {
  position: relative;
}
.redrange span {
  font-size: 0;
  position: relative;
}
.redrange span::before {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  left: 50%;
  z-index: 1;
  background: var(--text-light);
  border-radius: 50%;
  transform: translate(-50%, 0);
  top: 9px;
}
.redrange .active.rangeslider {
  background: var(--red);
  height: 24px;
  border-radius: 50%;
}

.redrange .active.rangeslider::before,
.redrange .newson.active.rangeslider::before {
  background: var(--white);
}
.redrange .newson.rangeslider::after {
  content: "";
  height: 18px;
  position: absolute;
  left: 3px;
  background: var(--red);
  border-radius: 50px;
  width: 80px;
  top: 3px;
}
.redrange .newson.rangeslider::before {
  background: var(--white);
}
.starfill svg path {
  fill: #ffb500;
}
.rate-court-ratings h4 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.slidermain-with-title-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.fill-star-with-ratings {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  border-radius: 30px;
  background: #f6f7f9;
  padding: 5px 10px;
}
.slidermain-with-title {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 15px 10px;
}
.membership-element-single-diff-para {
  margin-bottom: 15px;
}
.membership-element-single-diff-para p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.membership-element-single-diff-para p span {
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
}
.membership-element-single-wrap-up {
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 25px 15px 15px 15px;
  margin-top: 20px;
}
img.add-wishlist.crown {
  top: 10px;
  right: 45px;
  background: none;
  backdrop-filter: none;
}
img.add-wishlist.crown:hover {
  border: 1px solid #ffc42d;
}
span.dark-badge span {
  text-decoration: underline;
}
.subcription-modal-membership .plan-premium-main {
  background: #f6f7f9;
}
.subcription-modal-membership-top-text p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.subcription-modal-membership-top-text h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.subcription-modal-membership-top-text h6 span {
  text-decoration: line-through;
}
.subcription-modal-membership-top-text {
  margin-bottom: 20px;
}
EEDED .become-trainer-top-four-box {
  border-radius: 15px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #fff;
  padding: 10px;
}
.invite-player-main,
.invited-player-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.invite-player-main {
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: var(--white);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 10px;
}
.become-trainer-top-four-box {
  border-radius: 15px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  background: #fff;
  padding: 10px;
  margin-bottom: 20px;
}
.become-trainer-four-box-top-single {
  padding: 8px 20px 20px 20px;
  cursor: pointer;
}
.become-trainer-four-box-top-single:not(:last-child) {
  border-right: 1px solid #eeeded;
}
.become-trainer-four-box-top:not(:last-child) {
  border-bottom: 1px solid #eeeded;
}
.become-trainer-four-box-top:not(:first-child)
  .become-trainer-four-box-top-single {
  padding-top: 20px;
}
.invited-player-single h4 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18) !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
}
.invited-player-single p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 4px;
}
.invited-player-single {
  margin-bottom: 15px;
}
.table-grid-class {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.table-grid-class:has(.not-available.disabled) {
  display: block !important;
}
.booking-detail-right-middle-top img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.add-wishlist svg {
  width: 20px;
  height: 29px;
  object-fit: cover;
}
table .my-booking-table-heading {
  font-size: 16px !important;
  margin-bottom: 0 !important;
}
.rate-court-main .court-and-court-name h5,
.rate-court-main .court-and-court-name p {
  margin: 0 !important;
}
.rate-court-main .booking-detail-image-main {
  width: 50px;
  height: 50px;
}
.rate-court-main .booking-detail-image-main img {
  border-radius: 50% !important;
  object-fit: cover;
  width: 50px;
  height: 50px;
}
.font-green h2 {
  color: #38a505;
}
.navbar-toggler-icon {
  background-image: url("../images/toggler.svg");
}
.navbar-toggler:focus {
  outline: none;
  box-shadow: none !important;
}
.navbar-toggler {
  border: 1px solid var(--white);
}
.header-icons-wrapper {
  grid-gap: 10px;
}
.header-right-main-mobile {
  display: none !important;
}
.gmap-marker {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  position: relative;
}
.gmap-marker::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  top: 10px;
  right: 0;
  background: url("../images/blackRightArrow.svg") no-repeat;
  background-size: contain;
}
.gmapImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.gmapImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.gmapContent h5 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.gmapContent p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.google-map-modal .modal-body {
  min-height: 500px;
}
.google-map-modal .gm-style .gm-style-iw-c {
  min-width: 280px !important;
  padding: 15px !important;
}
.google-map-modal .gm-style .gm-style-iw-d {
  max-height: unset !important;
  overflow: hidden !important;
}
.location-search-group img {
  cursor: pointer;
}
.g-map-modal-body {
  height: 350px;
}
.pac-container.pac-logo {
  z-index: 9999;
}
.location-pin {
  cursor: pointer;
  z-index: 2;
}
.google-map-modal .gm-svpc {
  display: none;
}

.all-post-section-main .recent-result-wrapper-main {
  z-index: 1;
}
.all-post-section-main .recent-result-wrapper {
  background-size: 100%;
}
.all-post-section-main .recent-result-single-team {
  width: 50%;
}
/* .all-post-section-main .recent-result-scores-wrapper {
  justify-content: space-between;
} */
.all-post-section-main .recent-result-scores-wrapper h6 {
  padding: 0 35px;
}
.all-post-section-main .recent-result-wrapper {
  align-items: flex-start;
}
.slide-out-of-total-para {
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--white);
  background: var(--text-light);
  padding: 0 10px;
  border-radius: 10px;
}
.slide-out-of-total {
  position: relative;
}
.court1Image img {
  width: 70px;
  height: 70px;
}
.error.password-group.input-group button#showHidePassBtn {
  display: none;
}
.error.password-group.input-group input,
.error.phone-group.input-group input {
  border-right: 1px solid var(--red) !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.homeDashboardMain .dropdown-toggle::after {
  content: "";
  background: url("../images/threeDots.svg") no-repeat !important;
  background-size: contain;
  width: 24px !important;
  height: 24px !important;
  border: none !important;
  vertical-align: middle !important;
}
.homeDashboardMain .dropdown-menu.show {
  z-index: 1;
}
.profile-standard-after-initials-title,
.profile-trainer-after-initials-title,
.profile-premium-after-initials-title {
  position: relative;
  /* min-width: 80px; */
  max-width: fit-content;
  word-wrap: break-word;
}
.profile-standard-after-initials-title::after,
.profile-trainer-after-initials-title:after,
.profile-premium-after-initials-title:after {
  content: "S";
  color: var(--white) !important;
  font-family: var(--Outfit);
  font-size: 10px !important;
  border-radius: 60px;
  background: var(--blackdiff);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: absolute;
  left: 110%;
  top: 0;
  padding: 1px 6px;
}
/* .profile-trainer-after-initials-title:after {
  content: "T";
} */
.profile-premium-after-initials-title:after {
  content: "P";
}
/* .suggestion-single p{
  max-width: 95px;
  margin: 10px auto 14px auto;
} */
.booking-detail-participants-wrapper {
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
  padding: 15px;
  width: 100%;
}
.booking-detail-participants-single {
  display: flex;
  align-items: center;
  grid-gap: 10px;
}
.booking-detail-participants-single img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.booking-detail-participants-single:not(:last-child) {
  margin-bottom: 15px;
}
.booking-detail-participants-single h5 {
  font-size: var(--font-16);
  line-height: 100%;
  font-family: var(--Outfit);
  color: var(--blackdiff);
  font-weight: 600;
  margin-bottom: 5px;
}
.booking-detail-participants-single p {
  font-size: var(--font-14);
  line-height: 100%;
  font-family: var(--Outfit);
  color: var(--blackdiff);
  font-weight: 400;
}
.sticky-footer-btn {
  position: sticky;
  bottom: 0;
  background: var(--white);
}
.top-subcribe-modal-opener {
  background: url("../images/subscriber-bg.svg") no-repeat;
  position: relative;
  background-size: cover;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px 15px 15px 15px;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
}
.top-subcribe-modal-opener img {
  /* width: 45px;
  height: 45px;
  object-fit: cover;
  position: absolute;
  bottom: 73%;
  left: 45.4%; */
  display: none;
}
.top-subcribe-modal-opener-content p {
  max-width: 280px;
  margin: auto;
  color: var(--text-light);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 5px;
}
.top-subcribe-modal-opener-content p span {
  font-weight: 600;
  color: var(--blackdiff);
}
.top-subcribe-modal-opener-content h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.top-subcribe-modal-opener::after {
  content: "";
  background: url("../images/crown.png") no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  top: -20px;
  left: 45%;
  position: absolute;
}
.book-court-calendar .top-subcribe-modal-opener::after {
  width: 45px;
  height: 45px;
}
label.btn-up {
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 8px;
  width: 60px;
  height: 60px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  font-size: 16px;
  cursor: pointer;
  background: url("../images/gallery.png") no-repeat;
  background-size: 24px 24px;
  background-position: center;
}
.wrapper-all-selected-images {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  flex-wrap: wrap;
  flex-direction: row-reverse;
}
.homeDashboard-right-wrapper .activity-section-bottom-single {
  margin-bottom: 10px;
}
/* .all-sungle-likes-wrapper{
  height: 450px;
  overflow: auto;
} */
.invite-friends-main-global-search {
  flex-grow: 1;
  max-height: 400px;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}
.invite-friends-main-global-search::-webkit-scrollbar {
  display: none;
}
.all-followings {
  max-height: calc(100vh - 450px);
  overflow-y: auto;
}
.all-followings::-webkit-scrollbar {
  display: none;
}
.react-confirm-alert-overlay {
  z-index: 9999;
}
/* .like-modal-inner-wrapper {
  position: relative;
} */
.like-modal-inner-wrapper .write-comment-here {
  position: absolute;
  bottom: 15px;
  width: 39%;
  z-index: 99999;
  background: var(--white);
}

.single-country p {
  cursor: pointer;
}
.dark-border-check-box .form-check-input {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}
.dark-border-check-box .form-check-input :checked {
  border: 1px solid var(--blackdiff) !important;
}
.label-with-icon-main-wrapper img {
  width: 20px;
  height: 20px;
  filter: brightness(0);
}
.label-with-icon-main-wrapper .red-radio-btn:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
}
.label-with-icon-main-wrapper .red-radio-btn:not(:first-child) {
  padding-top: 15px;
}

.mobile-page-wrapper-main {
  max-width: 767px;
  margin: 0 auto;
  background: url("../images/responsive-image.jpg") no-repeat;
  background-size: 100vw 100vh;
  padding-top: 40px;
  padding-bottom: 60px;
}
img.mobile-logo-image {
  width: 90px;
  height: 90px;
}
.mobile-page-inner span {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  padding-left: 154px;
}
.mobile-page-inner span::after {
  content: "";
  position: absolute;
  width: 144px;
  height: 1px;
  background: #cdcacf;
  top: 10px;
  left: 0;
}
img.twomobImge {
  margin-top: 20px;
}
.mobile-page-inner p {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 10px auto 0 auto;
  max-width: 542px;
}
.mobile-page-inner h4 {
  color: var(--red);
  text-align: center;
  font-family: var(--Outfit);
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  max-width: 400px;
  margin: 15px auto 8px auto;
}
.mobile-page-inner {
  text-align: center;
}
.mobile-app-links {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}
.all-apointments-main-wraper .my-single-booking {
  box-shadow: none;
  border-radius: 0;
}
.all-apointments-main-wraper .my-single-booking:not(:last-child) {
  border-bottom: 1px solid #eeeded;
}
.all-apointments-main-wraper {
  max-height: 455px;
  overflow-y: auto;
}
.all-apointments-main-wraper::-webkit-scrollbar {
  display: none;
}
.appointment-detail-title {
  margin-bottom: 20px;
}
.settings-appointment-detail-wrapper .court1Image {
  width: 100px;
  height: 100px;
}
.settings-appointment-detail-wrapper .court1Image img {
  width: 100px;
  height: 100px;
}
.setting-court-detail-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 8px;
  width: 100%;
}
.setting-court-detail-content-inner {
  border-radius: 10px;
  background: rgba(246, 247, 249, 0.05);
  backdrop-filter: blur(20px);
  padding: 8px;
}
.setting-court-detail-wrapper {
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 20px;
}
.setting-court-detail-wrapper img {
  border-radius: 10px;
}
.setting-court-detail-content-inner p {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.setting-court-detail-content-inner h6 {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.setting-payment-detail.booking-detail-right-bottom {
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}
.setting-payment-detail .booking-detail-right-bottom-single:last-child {
  margin-bottom: 40px;
  border-bottom: 1px solid #eae9e9;
}
.setting-personal-information-top h5 {
  color: var(--white);
}
.setting-personal-information-top {
  border-radius: 20px 20px 0px 0px;
  background: linear-gradient(180deg, #ef333f 26.04%, #bd000c 100%);
  padding: 20px 20px 0 20px;
  height: 215px;
}
img.edit-icon-setting {
  filter: brightness(10);
}
.setting-personal-information-top-inner {
  border-bottom: 1px solid #f04651;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.club-detail-activity-tabs .nav-tabs .nav-link {
  color: #eeeeee;
}

.settings-personal-information-wrapper
  .club-detail-activity-tabs
  .nav-tabs
  .nav-link.active,
.club-detail-activity-tabs .nav-tabs .nav-link:hover {
  color: var(--white);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border-width: 0 0 1px 0;
  border-color: var(--white);
}
.settings-personal-information-wrapper .club-detail-activity-tabs {
  margin-top: -140px;
}
.setting-personal-profile-image {
  width: 149px;
  height: 149px;
  background: linear-gradient(180deg, #ef333f 37.5%, #bd000c 100%);

  border-radius: 50%;
  position: relative;

  margin: 30px auto 0 auto;
}
.setting-personal-profile-image img {
  width: 137px;
  height: 137px;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 2px solid var(--white);
}
.setting-personal-profile-edit-form p {
  color: var(--text-color);
  font-family: var(--Outfit);
  font-size: var(--font-12);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 6px;
}
.setting-personal-profile-edit-form {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
}
.setting-personal-profile-edit-form button {
  margin-top: 28px;
}
.setting-personal-profile-edit-form .form-select {
  background-size: 14px 14px !important;
}
.settings-availability-top-icon-single img {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.settings-availability-top-icon-single {
  padding: 11px;
  background: var(--headerColor);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}
.settings-availability-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.all-sungle-likes-wrapper.all-comments-main {
  height: 520px;
}
.settings-availability-inner-main h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 12px;
}
.settings-availability-inner-main .legends-of-available-slots-content {
  display: flex;
  max-width: unset;
  margin-top: 0;
  grid-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.settings-edit-availability-date-picker
  .react-datepicker__input-container
  input {
  background: #f6f7f9;
}
.settings-edit-availability-date-picker {
  margin-bottom: 25px;
}
.settings-availability-inner-main .dropIn-court label {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.settings-availability-inner-main button {
  margin-top: 40px;
}
.settings-availability-inner-main.settings-edit-schedule-main
  .legends-of-available-slots-inner-para:not(
    .disabled.legends-of-available-slots-inner-para
  ) {
  width: unset;
}
.settings-edit-schedule-main span {
  color: var(--blackdiff);
  text-align: right;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  margin-bottom: 12px;
  cursor: pointer;
}
.settings-availability-schedule-inner-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.settings-availability-schedule-inner-single:not(:first-child) {
  padding: 12px 0;
}
.settings-availability-schedule-inner-single:not(:last-child) {
  border-bottom: 1px solid #eae9e9;
}
.settings-availability-schedule-inner-single:last-child {
  padding-bottom: 0;
}
.settings-availability-schedule-inner-single span.dark-badge {
  border-radius: 45px;
}
.single-day-settings-availability-wrapper-single {
  border-radius: 5px;
  background: #f6f7f9;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02);
  padding: 15px 12px;
  display: flex;
  align-items: center;
  grid-gap: 30px;
}
.single-day-settings-availability-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.single-day-settings-availability-wrapper-single p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.single-day-settings-availability-wrapper-single img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  filter: brightness(0);
  cursor: pointer;
}
.single-day-settings-availability-wrapper-single.add-settings-availability {
  background: var(--white);
  border: 1px solid rgba(41, 31, 31, 0.1);
  width: 185px;
  justify-content: center;
  cursor: pointer;
}
/* .page-top-section-middle-tab {
  min-width: 330px;
} */
/* .page-top-section-middle-tab .tab-content {
  position: absolute;
  top: 175px;
} */
.page-top-section-middle-tab .nav-item a {
  padding: 10px 45px;
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--text-light) !important;
  background: var(--white);
}
.page-top-section-middle-tab .nav-item a.active {
  background: var(--red);
  color: var(--white) !important;
}
.page-top-section-middle-available-content-single {
  display: flex;
  grid-gap: 12px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 10px;
  position: relative;
}
.page-top-section-middle-available-content-single-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #66666650;
}
.page-top-section-middle-available-content-single-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.page-top-section-middle-available-content-single-content p {
  color: var(--text-light);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.page-top-section-middle-available-content-single-content h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.page-top-section-middle-available-content-single-content-inner {
  display: flex;
  grid-gap: 5px;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.page-top-section-middle-available-content-single-content-inner-single {
  padding: 5px 10px;
  border-radius: 30px;
  border: 1px solid rgba(41, 31, 31, 0.1);
  display: flex;
  grid-gap: 5px;
  align-items: center;
}
.page-top-section-middle-available-content-single-content-btns h5 {
  border-radius: 8px;
  background: #f6f7f9;
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 35px;
}
.page-top-section-middle-available-content-single-content-btns button {
  height: 38px;
  padding: 0 50px;
}
.page-top-section-middle-available-content-single-content-btns {
  display: flex;
  align-items: center;
  grid-gap: 5px;
}
.page-top-section-middle-tab .nav-pills {
  justify-content: center;
  margin-top: -83px;
  margin-bottom: 45px;
}
.page-top-section-middle-tab .tab-content > .active {
  margin: 0;
}
.coach-like-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.page-top-section-middle-available-content-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.coach-status {
  position: absolute;
  top: 0;
  right: 0;
}
.page-top-section-middle-booked-content h5 {
  color: var(--blackdiff);
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 5px;
  margin-bottom: 8px;
}
.CoachDeatail-main-wrapper .SingleCoachWithStatus-main {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  position: relative;
  max-width: 500px;
}
.CoachDeatail-main-wrapper .page-top-section-middle-available-content-single {
  box-shadow: none;
  background: none;
  border-radius: 0;
}
.page-top-section-middle-booked-bottom-section p {
  color: #fff;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  background: linear-gradient(180deg, #ef333f 37.5%, #bd000c 100%);
  padding: 14px 22px;
}
.page-top-section-middle-booked-bottom-section h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 15px;
}
.page-top-section-middle-booked-bottom-section {
  display: flex;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #eae9e9;
}
.SingleCoachWithStatus-main-bottom {
  padding: 20px;
}
.page-top-section-middle-booked-bottom-section span.dark-badge {
  padding: 14px 22px;
  border-radius: 5px;
  font-size: var(--font-16);
  /* max-width: 90px; */
}
.page-top-section-middle-booked-bottom-section-inner {
  display: flex;
  flex-direction: column;
}
.page-top-section-middle-booked-bottom-section-inner:first-child {
  border-right: 1px solid #eae9e9;
  padding-right: 40px;
}
.page-top-section-middle-booked-bottom-section-inner:last-child {
  padding-left: 40px;
}
.CoachDeatail-main-wrapper .booking-detail-right-bottom {
  max-height: unset;
}
.slide-out-of-total11 img {
  height: 400px;
  min-height: 400px;
  max-height: 400px;
}
.CoachDetailModal .book-court-section {
  box-shadow: none;
  padding: 0;
}
/* .CoachDetailModal td.week_cell, .CoachDetailModal th.week_cell {
  width: 50px;
} */
.CoachDetailModal .next_prev_align {
  margin-bottom: 20px;
}
.CoachDetailModal-book-court-section-wrapper {
  max-height: 460px;
  overflow: auto;
  padding-left: 15px;
  border-left: 1px solid #eae9e9;
}
.CoachDetailModal-book-court-section-wrapper::-webkit-scrollbar {
  display: none;
}
/* .CoachDetailModal-book-court-section-buttons {
  position: sticky;
  bottom: 0;
  right: 0;
  background: var(--white);
  width: 100%;
} */
.CoachDetailModal
  .page-top-section-middle-available-content-single-content-inner-single {
  padding: 5px 6px;
}
.settings-availability-price {
  margin-top: 15px;
}
.settings-edit-availability-date-picker
  .react-datepicker__input-container:after {
  background: url("../images/clock.svg") no-repeat;
}
.red-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--red);
  position: absolute;
  top: 50%;
  left: -10px;
}
.all-notifications-main .single-notification-single-wrapper {
  position: relative;
}
.level-information-on-badge-for-standard-user.level-information-on-badge {
  background: url("../images/goldenstar.jpg") no-repeat center center;
}
.CoachDetailModal-book-court-section-wrapper table {
  border-collapse: separate;
  border-spacing: 18px 0;
}
.CoachDetailModal-book-court-section-wrapper table th,
.CoachDetailModal-book-court-section-wrapper table td {
  padding: 0 !important;
  width: 50px;
  height: 50px;
}
.CoachDetailModal-book-court-section-wrapper td.week_cell {
  border: 1px solid rgba(41, 31, 31, 0.1);
}
.CoachDetailModal-book-court-section-wrapper tfoot {
  display: none;
}
.settings-availability-inner-main.settings-edit-schedule-main
  .legends-of-available-slots-inner-para {
  position: relative;
}
.settings-availability-inner-main.settings-edit-schedule-main
  .legends-of-available-slots-inner-para
  span {
  position: absolute;
  top: -12px;
  right: -7px;
}
.delete-icon-one {
  position: relative;
}
.delete-icon-one .delete-icon {
  position: absolute;
  bottom: 70%;
  right: -8px;
  cursor: pointer;
}
.table-responsive::-webkit-scrollbar {
  display: none;
}
.redrange .newson.rangeslider:last-child::after {
  width: 60px;
}
.red-radio-btn.plan-detail-check-box {
  width: 100%;
}
.red-radio-btn.plan-detail-check-box .form-check input {
  margin: 0;
}
.red-radio-btn.plan-detail-check-box .form-check {
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 100%;
  padding: 0;
}
.red-radio-btn.plan-detail-check-box .form-check label {
  color: var(--red);
  font-family: var(--Outfit);
  font-size: var(--font-22);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.plan-premium-main:not(:first-child) {
  margin-top: 20px;
}
p.para-golden {
  color: #ea8c00;
}
.all-my-bookings.data-not-found {
  display: block;
}
.diff-check {
  margin-top: 5px;
}
.diff-check input {
  margin: 0;
}
/* 
.g-map-modal-body div[role="button"] img{
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  object-fit: contain !important;
  border: 1px solid red !important;
  overflow: hidden !important;
}
.g-map-modal-body div[role="button"]{
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  overflow: hidden !important;
} */

.timer {
  background: red;
  padding: 6px 10px;
  border-radius: 5px;
}
.fw-500 {
  font-weight: 500 !important;
}
.profile-detail-badge-star {
  width: fit-content;
  margin: 10px auto 0 auto;
}
.rate-court-ratings .redrange .rangeslider:nth-child(2),
.rate-court-ratings .redrange .rangeslider:nth-child(3),
.rate-court-ratings .redrange .rangeslider:nth-child(4),
.rate-court-ratings .redrange .rangeslider:nth-child(5),
.rate-court-ratings .redrange .rangeslider:nth-child(6),
.rate-court-ratings .redrange .rangeslider:nth-child(7),
.rate-court-ratings .redrange .rangeslider:nth-child(8),
.rate-court-ratings .redrange .rangeslider:nth-child(9),
.rate-court-ratings .redrange .rangeslider:nth-child(10),
.rate-court-ratings .redrange .rangeslider:nth-child(12),
.rate-court-ratings .redrange .rangeslider:nth-child(13),
.rate-court-ratings .redrange .rangeslider:nth-child(14),
.rate-court-ratings .redrange .rangeslider:nth-child(15),
.rate-court-ratings .redrange .rangeslider:nth-child(16),
.rate-court-ratings .redrange .rangeslider:nth-child(17),
.rate-court-ratings .redrange .rangeslider:nth-child(18),
.rate-court-ratings .redrange .rangeslider:nth-child(19),
.rate-court-ratings .redrange .rangeslider:nth-child(20),
.rate-court-ratings .redrange .rangeslider:nth-child(22),
.rate-court-ratings .redrange .rangeslider:nth-child(23),
.rate-court-ratings .redrange .rangeslider:nth-child(24),
.rate-court-ratings .redrange .rangeslider:nth-child(25),
.rate-court-ratings .redrange .rangeslider:nth-child(26),
.rate-court-ratings .redrange .rangeslider:nth-child(27),
.rate-court-ratings .redrange .rangeslider:nth-child(28),
.rate-court-ratings .redrange .rangeslider:nth-child(29),
.rate-court-ratings .redrange .rangeslider:nth-child(30),
.rate-court-ratings .redrange .rangeslider:nth-child(32),
.rate-court-ratings .redrange .rangeslider:nth-child(33),
.rate-court-ratings .redrange .rangeslider:nth-child(34),
.rate-court-ratings .redrange .rangeslider:nth-child(35),
.rate-court-ratings .redrange .rangeslider:nth-child(36),
.rate-court-ratings .redrange .rangeslider:nth-child(37),
.rate-court-ratings .redrange .rangeslider:nth-child(38),
.rate-court-ratings .redrange .rangeslider:nth-child(39),
.rate-court-ratings .redrange .rangeslider:nth-child(40),
.rate-court-ratings .redrange .rangeslider:nth-child(42),
.rate-court-ratings .redrange .rangeslider:nth-child(43),
.rate-court-ratings .redrange .rangeslider:nth-child(44),
.rate-court-ratings .redrange .rangeslider:nth-child(45),
.rate-court-ratings .redrange .rangeslider:nth-child(46),
.rate-court-ratings .redrange .rangeslider:nth-child(47),
.rate-court-ratings .redrange .rangeslider:nth-child(48),
.rate-court-ratings .redrange .rangeslider:nth-child(49),
.rate-court-ratings .redrange .rangeslider:nth-child(50) {
  /* opacity: 0; */
  margin: 0;
}

.rate-court-ratings .redrange .rangeslider:nth-child(2)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(3)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(4)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(5)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(6)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(7)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(8)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(9)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(10)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(12)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(13)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(14)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(15)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(16)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(17)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(18)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(19)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(20)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(22)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(23)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(24)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(25)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(26)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(27)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(28)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(29)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(30)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(32)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(33)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(34)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(35)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(36)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(37)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(38)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(39)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(40)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(42)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(43)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(44)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(45)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(46)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(47)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(48)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(49)::before,
.rate-court-ratings .redrange .rangeslider:nth-child(50)::before {
  display: none;
}
.redrange .starfill.rangeslider::before {
  background: var(--white);
}

.redrange .starfill.rangeslider::after {
  content: "";
  height: 18px;
  position: absolute;
  left: 3px;
  background: var(--red);
  border-radius: 0;
  width: 100%;
  top: 3px;
}
.rate-court-ratings .redrange .rangeslider svg {
  position: relative;
  top: 30px;
}
.rate-court-ratings .slidermain-with-title {
  padding: 15px 10px 40px 10px;
}
.rate-court-ratings .slidermain-with-title:not(:last-child) {
  margin-bottom: 10px;
}
.redrange .starfill.rangeslider:not(:last-child) {
  margin-right: 0;
}
.rate-court-ratings .redrange .active.rangeslider::before {
  display: block;
}

.rate-court-ratings .redrange .rangeslider.active {
  background: var(--red);
  height: 24px;
  border-radius: 44% 50% 50% 45%;
  min-width: 24px;
}

.rate-court-ratings .redrange .starfill.rangeslider:first-child::after {
  border-radius: 50px 0 0px 50px;
}
.club-detail-content-wrapper .add-wishlist {
  right: 0;
}
.enter-score-inner-profile-score input {
  max-width: 170px;
}
.having-list-inside ul {
  list-style-type: disc;
  margin-left: 10px;
}
.having-list-inside ul li:not(:last-child) {
  margin-bottom: 5px;
}
.membership-element-single-wrap-up span.dark-badge {
  margin-bottom: 10px;
  display: block;
}
.custom-loader.spinner-grow {
  width: 50px;
  height: 50px;
  border: 8px solid var(--headerColor);
  border-radius: 50%;
  border-top: 8px solid var(--red);
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 999;
  opacity: 1;
  background-color: transparent;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.small-height-btn {
  max-height: 30px;
}
.view-more-btn {
  margin-top: 20px;
}
.my-booking-main .my-single-booking {
  min-height: 131px;
}
.invite-only-player-modal-main-wrapper .invite-friends-main {
  max-height: 400px !important;
  overflow: auto;
}
.invite-only-player-modal-main-wrapper .invite-friends-main::-webkit-scrollbar {
  display: none;
}
.form-check.align-items-center {
  align-items: center !important;
}
.form-check.align-items-center input {
  margin-top: 12px;
}
.sign-up-main .sign-in-inputs .no-margin-input input {
  margin-bottom: 0;
}
.sign-up-main .sign-in-inputs .no-margin-input {
  margin-top: 5px;
}
.only-one-trainer:not(:last-child) .twoInOneBooking {
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 15px;
}
.seeall {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.max-500 {
  max-width: 500px;
}
.single-country p:hover {
  color: var(--red) !important;
}
.hoverblack.club-detail-activity-tabs .nav-tabs .nav-link {
  color: var(--blackdiff);
  padding: 8px 16px !important;
  font-size: var(--font-20) !important;
  border-width: 0 0 2px 0;
  /* border-color: var(--blackdiff) !important; */
}
.hoverblack.club-detail-activity-tabs .nav-tabs .nav-link:hover {
  color: var(--blackdiff);
  padding: 8px 16px !important;
  font-size: var(--font-20) !important;
  border-width: 0 0 2px 0;
  border-color: var(--red) !important ;
}
.hoverblack.club-detail-activity-tabs .nav-tabs .nav-link.active {
  border-color: var(--red) !important ;
}
.booking-detail-right-main-wrapper .recent-result-wrapper-main h6 {
  font-size: var(--font-14);
}
.coach-like-btn.active svg * {
  fill: var(--red);
  stroke: var(--red);
}
.subscription-modal-main .modal-body {
  max-height: 580px;
}
.visibility-hidden {
  visibility: hidden;
  max-width: 120px;
  min-width: 120px;
  width: 120px;
}
.find-coach-main
  .page-top-section-middle-available-content-single-content-btns
  button {
  height: 38px;
  padding: 0 25px;
}
.padding-not-needed h5 {
  padding: 10px 15px;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
}
.activity-section-bottom-single.activity-section-bottom-single-diff:not(
    :last-child
  ) {
  margin-bottom: 15px;
}
.activity-section-bottom-single.activity-section-bottom-single-diff {
  position: relative;
}
.my-delt-icon {
  position: absolute;
  top: 40px;
  right: 15px;
  cursor: pointer;
}
.my-image {
  width: 70px !important;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}
.plausicon {
  border-radius: 50%;
  background: var(--blackdiff);
  border-color: var(--blackdiff);
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 50px auto;
  font-size: 22px;
}
.my-cart-item-combine
  .activity-section-bottom-single-diff:not(:first-child)
  .activity-section-bottom-single-top {
  display: none;
}
.activity-section-bottom-single.activity-section-bottom-single-diff:not(
    :last-child
  ) {
  margin-bottom: 0;
}
.my-cart-item-combine {
  margin-bottom: 15px;
}
.my-cart-item-combine .activity-section-bottom-single:not(:last-child) {
  border-radius: 10px 10px 0 0;
  padding-bottom: 0;
}
.my-cart-item-combine
  .activity-section-bottom-single:not(:first-child)
  .my-delt-icon {
  display: none;
}
.my-cart-item-combine .activity-section-bottom-single:not(:first-child) {
  border-radius: 0 0 10px 10px !important;
  box-shadow: none;
}
.my-cart-item-combine
  .activity-section-bottom-single.activity-section-bottom-single-diff:not(
    :first-child
  ) {
  margin: 0;
  border-top: 1px solid #bfbcbc80 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.rounded-plus {
  border: 1px solid var(--blackdiff);
  background: var(--headerColor);
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.delete-icon-in-add-to-cart {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.special-dropdown > button,
.special-dropdown > button:hover,
.special-dropdown > button:focus {
  background: var(--red);
  padding: 2px 10px;
  border: none;
}
.settings-personal-information-wrapper
  .club-detail-activity-tabs
  .nav-tabs
  .nav-link {
  border: 1px solid transparent;
  border-width: 0 0 1px 0;
}
.settings-personal-information-wrapper
  .club-detail-activity-tabs
  .nav-tabs
  .nav-link.active {
  border: 2px solid var(--white);
  border-width: 0 0 2px 0;
}
.loc-btn:focus,
.loc-btn:hover {
  background-color: transparent !important;
  color: var(--blackdiff);
}
.loc-btn {
  border-color: #ced4da !important;
  color: var(--blackdiff);
  background-color: transparent !important;
}
.loc-btn img {
  filter: brightness(0);
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.loc-btn svg {
  width: 20px;
  height: 20px;
}
.all-see-all img {
  cursor: pointer;
}
.homeDashboard-right-wrapper .active.single-notification-single-wrapper {
  border-radius: 10px;
  background: #ffeced;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 10px;
}
.homeDashboard-right-wrapper .single-notification-single-wrapper {
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 10px;
  background: var(--white);
}
.homeDashboard-right-wrapper
  .single-notification-single-wrapper:not(:last-child) {
  margin-bottom: 10px;
}
.add-group-modal-main .profileImageSelection .image-select {
  border: 1px solid #f3c8ca;
  width: 60px;
  height: 60px;
  object-fit: none;
}
.add-group-modal-main .profileImageSelection.group-profile .image-select {
  object-fit: cover;
}
.my-add-btn {
  width: 30px !important;
  height: 30px !important;
  padding: 0;
}
.font14 {
  font-size: 14px !important;
}
.profile-standard-after-initials-title.group_admin::after {
  content: "A";
}
.invite-friends-btn .dropdown button {
  padding: 0;
}
.my-differenr-dropdown button {
  background: transparent !important;
  padding: 0;
  border: none !important;
}
.my-differenr-dropdown button::after {
  display: none;
}
.all-results-main-in-club-detail-page {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 15px;
  max-width: 720px;
  overflow: auto;
}
/* .my-different-select.form-select option{
padding: 5px !important;
} */
.my-different-select {
  border: none !important;
  /* background-size: 12px 12px !important; */
}
.my-different-select:focus,
.my-different-select:focus-visible,
.my-different-select:focus-within {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.view-more-btn button {
  max-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: var(--font-16);
  font-weight: 500;
}
.min-heighted-modal .modal-body {
  min-height: 580px !important;
  max-height: 581px !important;
  overflow: auto;
}
.range-parent {
  position: relative;
}
.dot-range-parent {
  position: absolute;
  top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.dot-range {
  /* position: absolute; */
  /* top: 17px;
  left: 5px; */
  height: 5px;
  width: 5px;
  background-color: #fff;
  border-radius: 50%;
}
.dot-range-parent-number {
  top: 45px;
}
.my-schedule-in-setting-main .month-view-calender th,
.my-schedule-in-setting-main .month-view-calender td * {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.my-schedule-in-setting-main .month-view-calender th {
  padding: 0 0 15px 0 !important;
  border-bottom: 1px solid #e1e1e3;
}
.my-schedule-in-setting-main .month-view-calender td {
  height: 70px;
}
.full-cal-month-titile h4 {
  color: var(--blackdiff);
  text-align: center;
  font-family: var(--Outfit);
  font-size: var(--font-20);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.disable.table_body_cell .main-cell {
  visibility: hidden;
}
.my-schedule-in-setting-main .full-calender-buttons {
  margin-bottom: 20px;
}

/* .my-schedule-in-setting-main .event-cell {
  position: relative;
}
.my-schedule-in-setting-main .event-cell::after {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: rgba(41, 31, 31, 0.15);
  position: absolute;
  content: " ";
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
} */
.my-schedule-in-setting-main .table_body_cell.dayno.today .event-cell::after {
  background: var(--white) !important;
}
.main-cell {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
}
.my-schedule-in-setting-main .table_body_cell.dayno.today .main-cell {
  background: var(--red);
  border-radius: 50%;
}
.my-schedule-in-setting-main .table_body_cell.dayno.today .main-cell * {
  color: var(--white);
}
.my-schedule-in-setting-main {
  margin-bottom: 15px;
}
.my-schedule-time-single {
  display: flex;
  align-items: center;
}
.my-schedule-time-single h6 {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-16);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}
.my-schedule-time-single p {
  color: var(--blackdiff);
  font-family: var(--Outfit);
  font-size: var(--font-14);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-width: max-content;
}
.my-schedule-time-single-content-all {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 480px;
  overflow: auto;
}
.my-schedule-time-single-content-single {
  border-radius: 10px;
  background: #f6f7f9;
  padding: 8px 10px;
  min-width: 200px;
}
.my-schedule-time-single-content-all::-webkit-scrollbar {
  display: none;
}
.my-schedule-time-single-time {
  padding-right: 12px;
  border-right: 1px solid #e1e1e3;
  margin-right: 12px;
}
.my-schedule-time-single:not(:last-child) {
  margin-bottom: 15px;
}
.my-schedule-time-main {
  max-height: 250px;
  overflow: auto;
}
.my-schedule-time-main::-webkit-scrollbar {
  display: none;
}
.range-parent div:focus-visible {
  outline: none;
}
.sign-in-main.forgot-password-main .sign-in-inner-main {
  min-height: 600px;
}
.sign-in-main.forgot-password-main .sign-in-wrapper {
  top: 50%;
}
.my-red-dot {
  position: relative;
}
.my-red-dot:after {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--red);
  position: absolute;
  top: 5px;
  left: 50%;
  content: "";
  transform: translate(-50%, 0);
}
.table_body_cell.today .my-red-dot:after {
  background: var(--white);
}
.single-post-wrapper.FindClubCard-main .dropdown-menu.show a {
  padding: 3px 8px !important;
}
.single-post-wrapper.FindClubCard-main .dropdown-menu.show a:hover img {
  filter: brightness(0) saturate(100%) invert(32%) sepia(80%) saturate(5890%)
    hue-rotate(343deg) brightness(103%) contrast(87%);
}
.loading {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid red;
  width: 45px;
  height: 45px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  z-index: 2 !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader-main {
  border-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.04);
  padding: 15px 25px;
  text-align: center;
  background: var(--white);
  max-width: 100px;
  margin: auto;
  z-index: 2 !important;
}
.loader-over-lay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2 !important;
}
.only-for-invite-club-friend .modal-body {
  max-height: 500px !important;
}
.only-for-add-club-friend .modal-body {
  max-height: 418px !important;
}
body:has(.loader-over-lay) {
  overflow: hidden !important;
}
.unfollow-and-three-dots button span {
  color: var(--white);
  font-family: var(--Outfit);
  font-size: var(--font-18);
  font-style: normal;
  font-weight: 600;
}
.unfollow-and-three-dots button:focus span,
.unfollow-and-three-dots button:focus-within span,
.unfollow-and-three-dots button:focus-visible span,
.unfollow-and-three-dots button:hover span {
  color: var(--red);
}
.unfollow-and-three-dots .custom-loader.spinner-grow {
  width: 30px;
  height: 30px;
  border: 5px solid var(--headerColor);
  border-radius: 50%;
  border-top: 5px solid var(--red);
  animation: spin 2s linear infinite;
  z-index: 999;
  opacity: 1;
  background-color: transparent;
  position: absolute;
  right: 100px;
}
.comment-here-post-text .custom-loader.spinner-grow {
  border: 4px solid var(--white);
  border-top: 4px solid var(--red);
  width: 30px;
  height: 30px;
}
.editProfilePencil-wrapper {
  position: relative;
}
.editProfilePencil {
  position: absolute;
  bottom: 0;
  right: 50%;
  position: absolute;
  bottom: 7px;
  right: 35%;
  cursor: pointer;
}
img.my-white-icon {
  filter: brightness(10);
}
.club-detail-map-top.page-top-section-right-single-wrapper img {
  width: 16px;
  height: 16px;
  filter: brightness(0);
  object-fit: cover;
}
.invite-friends-btn .dropdown-toggle::after {
  display: none;
}
/* .my-error.input-group{
  flex-wrap: nowrap !important;
} */
.my-error.input-group input {
  background-image: none !important;
}
.my-error.input-group button {
  border-radius: 0 5px 5px 0 !important;
}
.my-search-diff {
  border: 1px solid #66666650 !important;
  border-radius: 20px !important;
}
.one-play-modal-main .modal-body {
  max-height: 400px;
  min-height: 400px;
}
.special-table-in-modal .table {
  border-collapse: separate;
  border-spacing: 5px;
  margin-bottom: 10px;
}
.special-table-in-modal .table tr * {
  background: #f6f7f9 !important;
  color: var(--blackdiff);
}
.special-table-in-modal .table tr th,
.special-table-in-modal .table tr td {
  padding: 8px 10px !important;
}
.special-table-in-modal .table tr td:first-child {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.special-table-in-modal .table tr td:last-child {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.special-table-in-modal {
  padding: 0 10px 10px 10px;
  background: var(--white);
  border-radius: 0 0 10px 10px;
}
.special-table-in-modal .red-text{
  color: var(--red) !important;
}
.special-table-in-modal-listing ul{
  list-style-type: disc;
  padding-left: 25px;
}