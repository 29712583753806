@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --black: #000000;
  --blackdiff: #111111;
  --headerColor: #291f1f;
  --white: #ffffff;
  --border-color: #aaaaaa;
  --red: #ef333f;
  --light-blue: #4a4a4a;
  --grey: #939393;
  --text-light: #666666;
  --text-color: #DDDDDD;
  --Outfit: "Outfit", sans-serif;
  --font-80: 80px;
  --font-50: 50px;
  --font-38: 38px;
  --font-32: 32px;
  --font-30: 30px;
  --font-42: 42px;
  --font-28: 28px;
  --font-26: 26px;
  --font-24: 24px;
  --font-22: 22px;
  --font-20: 20px;
  --font-18: 18px;
  --font-16: 16px;
  --font-14: 14px;
  --font-12: 12px;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.post,
.page {
  margin: 0;
}

.row {
  margin: 0;
}

.row>* {
  padding-left: 0;
  padding-right: 0;
}

body {
  width: 100%;
  font-family: var(--Outfit);
  overflow-x: hidden;
  background: #F6F7F9;
}

a {
  text-decoration: none !important;
  color: inherit;
  color: inherit !important;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.big-container {
  max-width: 1240px;
  padding: 0;
}

.small-container {
  max-width: 1040px;
  padding: 0;
}
.height50{
  height: 50px;
  max-height: 50px;
  min-height: 50px
}
.width50{
  width: 50px;
  max-width: 50px;
  min-width: 50px
}
.width100{
  width: 100px;
  max-width: 100px;
  min-width: 100px
}
.width150{
  width: 150px;
  max-width: 150px;
  min-width: 150px
}
.width200{
  width: 200px;
  max-width: 200px;
  min-width: 200px
}
.width250{
  width: 250px;
  max-width: 250px;
  min-width: 250px
}
.width300{
  width: 300px;
  max-width: 300px;
  min-width: 300px
}
.width350{
  width: 350px;
  max-width: 350px;
  min-width: 350px
}
.width400{
  width: 400px;
  max-width: 400px;
  min-width: 400px
}
.width450{
  width: 450px;
  max-width: 450px;
  min-width: 450px
}
.width500{
  width: 500px;
  max-width: 500px;
  min-width: 500px
}
.red-text {
  color: var(--red);
}
.cursor-pointer {
  cursor: pointer !important;
}

* ::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}


* ::-webkit-scrollbar-track {
  background: #f6f7f9;
  border-radius: 10px;
}


* ::-webkit-scrollbar-thumb {
  background: #aaacaf;
  border-radius: 10px;
}
.dark-color-text {
  color: var(--blackdiff) !important;
}